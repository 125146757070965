import PropTypes from 'prop-types';
import uuid from 'react-uuid';
import cx from 'classnames';
import React, {
  useState,
  useEffect
} from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

import { useFirebase } from '../_helpers/fetch';

const Search = (props) => {
  const {
    query,
    searchfnc,
    dataprop,
    value,
    valueKey,
    optionDisplay
  } = props;
  const activeSearch = true;

  const [dataobjs, SetDataObjs] = useState([]);

  const selected = (typeof value !== 'undefined') ? dataobjs.filter((res) => res[valueKey] === value) : [];
  const searchId = `${dataprop}_${uuid()}`;

  const {
    data,
    loading,
    error,
    firebaseCall
  } = useFirebase(query.name, query.options, query.isMounted, query.initData, query.mergeField);
  // const {
  //   data,
  //   loading,
  //   error
  // } = useQuery(query.url, query.options, query.isMounted, query.initData);

  const displayString = (options, optiondisplay) => {
    const displayStr = [];
    optiondisplay.forEach((option) => {
      const displaycheck = option.indexOf('option_');
      if (displaycheck === -1) displayStr.push(option);
      else displayStr.push(options[option.split('option_')[1]]);
    });
    return displayStr.join('');
  };

  useEffect(() => {
    firebaseCall();
  }, []);
  useEffect(() => {
    if (dataobjs.length === 0 && typeof data !== 'undefined' && data.length !== dataobjs.length) SetDataObjs(data);
  }, [data, dataobjs.length, dataprop]);

  let typeaheadobj;

  if (loading) return <div>Loading Search Options...</div>;
  if (error) return <div>Error Loading Search Options</div>;
  if (!data) return <div>No Data Found to Search</div>;

  return (
    <>
      <div className={cx('search-wrapper', {
        active: activeSearch,
      })}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {/* <div className="input-holder"> */}
          <FontAwesomeIcon icon={faSearch} />
        &nbsp;
          <div style={{ width: '100%' }}>
            <Typeahead
              // className="search-input"
              onChange={(e) => {
                searchfnc(e);
              }}
              options={dataobjs}
              labelKey={(option) => displayString(option, optionDisplay)}
              type="text"
              name={dataprop}
              id={`${dataprop}_${uuid()}`}
              inputProps={{ id: searchId }}
              ref={(ref) => { typeaheadobj = ref; }}
              selected={selected}
            />
          </div>
          <button
            type="button"
            aria-label="Close Search"
            style={{ marginLeft: '0.2rem' }}
            onClick={() => {
              searchfnc();
              typeaheadobj.clear();
            }}
            className="close"
          />
        </div>
      </div>
    </>
  );
};

Search.propTypes = {
  query: PropTypes.shape({
    name: PropTypes.string,
    // url: PropTypes.string,
    options: PropTypes.objectOf(PropTypes.string),
    // options: PropTypes.objectOf(PropTypes.object, PropTypes.string, PropTypes.number),
    isMounted: PropTypes.objectOf(PropTypes.bool),
    initData: PropTypes.arrayOf(PropTypes.object),
    mergeField: PropTypes.string
  }),
  searchfnc: PropTypes.func,
  dataprop: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  valueKey: PropTypes.string,
  optionDisplay: PropTypes.arrayOf(PropTypes.string)
};
Search.defaultProps = {
  query: {
    url: '',
    options: {},
    isMounted: { current: false },
    initData: []
  },
  searchfnc: () => { },
  dataprop: '',
  value: undefined,
  valueKey: '',
  optionDisplay: [],
};

export default Search;
