/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React, {
  useState,
  useContext,
  useEffect,
  useRef
} from 'react';
import { Link } from 'react-router-dom';
import {
  Table,
  Container,
  FormGroup,
  Row,
  Col,
  Label,
  Input,
  Button
} from 'reactstrap';
import { toast } from 'react-toastify';
import { RadioGroup, Radio } from 'react-radio-group';

import Loader from 'react-loader-spinner';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';

import { UserContext } from '../providers/UserProvider';
import { useFirebase, useFirebaseMutation } from '../_helpers/fetch';
import Breadcrumbs from '../_GlobalComponents/breadcrumb';
import Photos from '../_DataComponents/photos';
import { config } from '../firebase';
import Confirm from '../_GlobalComponents/Confirm';

const FixtureInstallation = (props) => {
  const { match } = props;

  const [data, setData] = useState({
    FixtureID: '',
    Circuit: '',
    FixtureType: '',
    Notes: '',
    LastModifiedBy: '',
    LastModifiedDate: '',
    FixtureInstallation: {
      ExtensionHeight: '',
      FlangeType: '',
      lastModifiedBy: '',
      lastModifiedDate: '',
      BaseAdjustedAndLeveled: false,
      BaseAdjustedAndLeveledQC: '',
      SiliconeSealant: false,
      SiliconeSealantQC: '',
      P606Installed: false,
      P606InstalledQC: '',
      BellsEndsInstalled: false,
      BellsEndsInstalledQC: '',
      BaseCanInteriorCleaned: false,
      BaseCanInteriorCleanedQC: '',
      CablePulled: false,
      CablePulledQC: '',
      TransformerStandInstalled: false,
      TransformerStandInstalledQC: '',
      GroundWireAttached: false,
      GroundWireAttachedQC: '',
      ConnectorKitInstalled: false,
      ConnectorKitInstalledQC: '',
      TransformerInstalled: false,
      TransformerInstalledQC: '',
      LightControllerReInstalled: false,
      LightControllerReInstalledQC: '',
      CircuitTagsInstalled: false,
      CircuitTagsInstalledQC: '',
      FixtureConnectedTo: false,
      FixtureConnectedToQC: '',

      LAWAInspectionQACheck: '',

      InstallFixtureWithGasket: false,
      InstallFixtureWithGasketQC: '',
      BoltDownFixture: false,
      BoltDownFixtureQC: '',
      TorqueBolts: false,
      TorqueBoltsQC: '',

      MarkFixtureComplete: false,
      MarkFixtureCompleteName: '',
      MarkFixtureCompleteDate: '',

      FinalInspectionUser: '',
      FinalInspectionQC: '',
      FinalInspectionQA: '',
      FinalInspectionDate: ''
    },
    _NextFixture: {
      id: '',
      fixtureID: ''
    },
    _PreviousFixture: {
      id: '',
      fixtureID: ''
    }
  });
  const isComponentMounted = useRef(true);
  const {
    data: fixtureData,
    loading: fixtureLoading,
    // error: fixtureLoading,
    firebaseCall: fixtureCall,
    refetch: newFixtureCall
  } = useFirebase('getFixtureData', { id: match.params.id }, isComponentMounted, [], 'data.id');

  const {
    data: submitData,
    loading: submitLoading,
    error: submitError,
    firebaseCall: submitCall
  } = useFirebaseMutation('submitFixtureData', { data });

  useEffect(() => {
    fixtureCall();
  }, []);
  useEffect(() => {
    newFixtureCall({ id: match.params.id });
  }, [match.params.id]);

  useEffect(() => {
    if (fixtureData.length > 0) {
      setData(fixtureData[0]);
    }
  }, [fixtureData]);

  useEffect(() => {
    if (typeof submitData !== 'undefined') {
      toast.success('Record Saved Successfully', { autoClose: 2000 });
    }
  }, [submitData, submitLoading, submitError]);
  const user = useContext(UserContext);

  const [approvalState, setApprovalState] = useState({
    proceedLabel: 'Uncheck',
    cancelLabel: 'Cancel',
    title: 'Uncheck Approval',
    modalBody: 'Would you like to uncheck this selection?',
    show: false,
    // eslint-disable-next-line no-use-before-define
    proceed: (approved, r) => { uncheckRec(approved, r); },
    enableEscape: true,
    record: undefined
  });
  const uncheckRec = (approved, record) => {
    const d = record.record;
    const { name } = record;
    if (approved) {
      const rec = { ...d };
      rec.FixtureInstallation[name] = '';
      rec.FixtureInstallation.lastModifiedBy = user.email;
      rec.FixtureInstallation.lastModifiedDate = new Date().toJSON();
      rec.LastModifiedBy = user.email;
      rec.LastModifiedDate = new Date().toJSON();
      setData({ ...rec });
    }
    setApprovalState({ ...approvalState, show: false });
  };
  const handleApproval = (record) => {
    setApprovalState({ ...approvalState, show: true, record });
  };

  const handleUserConfirm = (event) => {
    const d = { ...data };
    const display = `${user.name} (${user.email})\n${new Date().toLocaleString('en-US', { timeZone: 'CST', timeZoneName: 'short' })}`;
    if (d.FixtureInstallation[event.target.name] !== '') {
      // eslint-disable-next-line no-restricted-globals
      handleApproval({ record: d, name: event.target.name });
    }
    else {
      d.FixtureInstallation[event.target.name] = display;
      d.FixtureInstallation.lastModifiedBy = user.email;
      d.FixtureInstallation.lastModifiedDate = new Date().toJSON();
      d.LastModifiedBy = user.email;
      d.LastModifiedDate = new Date().toJSON();
      setData({ ...d });
    }
  };
  const handleChange = (event, name) => {
    try {
      const d = { ...data };
      if (typeof event.target !== 'undefined' && event.target.type.toLowerCase() === 'checkbox') {
        const id = name || event.target.name;
        d.FixtureInstallation[id] = event.target.checked;
      }
      else {
        const id = name || event.target.name;
        const value = typeof name !== 'undefined' ? event : event.target.value;
        if (id === 'Notes') {
          d[id] = value;
        }
        else {
          d.FixtureInstallation[id] = value;
        }
      }
      d.FixtureInstallation.lastModifiedBy = user.email;
      d.FixtureInstallation.lastModifiedDate = new Date().toJSON();
      d.LastModifiedBy = user.email;
      d.LastModifiedDate = new Date().toJSON();
      setData({ ...d });
    }
    catch (err) {
      toast.error(err.message, { autoClose: 8000 });
    }
  };
  const submit = () => {
    submitCall();
  };
  const displayConfirmedBy = (dataField) => (dataField !== '' ? <><br /><div>CB: {dataField.split('\n')[0]}</div><div>CB: {dataField.split('\n')[1]}</div></> : <></>);
  const handleMarkComplete = (event) => {
    const d = { ...data };
    d.FixtureInstallation.MarkFixtureComplete = event.target.checked;
    if (event.target.checked) {
      d.FixtureInstallation.MarkFixtureCompleteName = user.name;
      // eslint-disable-next-line prefer-destructuring
      d.FixtureInstallation.MarkFixtureCompleteDate = new Date().toJSON().split('T')[0];
    }
    else {
      d.FixtureInstallation.MarkFixtureCompleteName = '';
      d.FixtureInstallation.MarkFixtureCompleteDate = '';
    }
    d.FixtureInstallation.lastModifiedBy = user.email;
    d.FixtureInstallation.lastModifiedDate = new Date().toJSON();
    d.LastModifiedBy = user.email;
    d.LastModifiedDate = new Date().toJSON();
    setData({ ...d });
  };

  const submitToastId = useRef(null);
  useEffect(() => {
    if (submitLoading) {
      submitToastId.current = toast('Submitting Record', {
        position: 'top-center',
        autoClose: false
      });
    }
    else if (!submitLoading && submitToastId.current !== null) {
      toast.dismiss(submitToastId.current);
    }
  }, [submitLoading]);

  const isQcDisabled = !user.qc && !user.admin && !user.qa;
  const isQaDisabled = !user.admin && !user.qa;

  return (
    <>
      <Breadcrumbs
        path={[
          { name: 'Fixtures', active: true, link: '/' },
          { name: `Fixture Installation - ${data.FixtureID}`, active: false }
        ]}
      />
      <div style={{
        display: 'flex', width: '100%', justifyContent: 'space-between', position: 'sticky', zIndex: '100', top: '0', backgroundColor: 'white'
      }}
      >

        <div>
          <Button><Link to={`/demo/${data.id}`} style={{ textDecoration: 'none', color: 'inherit' }}>Demo Checklist</Link></Button>
          &nbsp;
          {data.BaseCan !== 'EXISTING' ? <Button><Link to={`/can/${data.id}`} style={{ textDecoration: 'none', color: 'inherit' }}>Can Installation</Link></Button> : null}
          &nbsp;
          <Button><Link to={`/pdf/${data.id}`} style={{ textDecoration: 'none', color: 'inherit' }}>PDF Output</Link></Button>
        </div>
        <div>
          {data._PreviousFixture.id !== '' ? <Button outline color="info"><Link style={{ textDecoration: 'none', color: 'inherit' }} to={`/fixture/${data._PreviousFixture.id}`}>&larr; {data._PreviousFixture.fixtureID}</Link></Button> : null}
          &nbsp;&nbsp;&nbsp;
          {data._NextFixture.id !== '' ? <Button outline color="info"><Link style={{ textDecoration: 'none', color: 'inherit' }} to={`/fixture/${data._NextFixture.id}`}>{data._NextFixture.fixtureID} &rarr;</Link></Button> : null}
        </div>
        <div>
          <Button className="bg-success" onClick={submit}>Save</Button>
          &nbsp;
          <Button className="bg-danger"><Link to="/" style={{ textDecoration: 'none', color: 'inherit' }}>Cancel</Link></Button>
        </div>
      </div>
      {
        submitLoading ? (
          <div style={{
            display: 'block', position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, backgroundColor: '#efefef', opacity: 0.5, zIndex: 100
          }}
          />
        ) : <></>
      }
      <Container fluid>
        {
          fixtureLoading ? (
            <div style={{
              display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'
            }}
            >
              <Loader
                type="TailSpin"
                color="#3b78e7"
                height={150}
                width={150}
              />
              Loading Fixture Data...
            </div>
          )
            : (
              <>
                <Confirm
                  proceedLabel={approvalState.proceedLabel}
                  cancelLabel={approvalState.cancelLabel}
                  title={approvalState.title}
                  modalBody={approvalState.modalBody}
                  show={approvalState.show}
                  proceed={approvalState.proceed}
                  enableEscape={approvalState.enableEscape}
                  record={approvalState.record}
                />
                <Row>
                  <Col md={{ size: 4 }}>
                    <FormGroup>
                      <Label for="FixtureID">Fixture ID:</Label>
                      <Input type="text" name="FixtureID" value={data.FixtureID || ''} readOnly />
                    </FormGroup>
                  </Col>
                  <Col md={{ size: 4 }}>
                    <FormGroup>
                      <Label for="System">System:</Label>
                      <Input type="text" name="System" value={data.System || ''} readOnly />
                    </FormGroup>
                  </Col>
                  <Col md={{ size: 4 }}>
                    <FormGroup>
                      <Label for="BaseCan">Base Can:</Label>
                      <Input type="text" name="BaseCan" value={data.BaseCan || ''} readOnly />
                    </FormGroup>
                  </Col>
                </Row>
                <div style={{ overflowX: 'auto' }}>
                  <Table striped>
                    <thead>
                      <tr>
                        <td />
                        <td style={{ textAlign: 'center', fontWeight: 'bold' }}>
                          Contractor
                        </td>
                        <td className="qc-color" style={{ textAlign: 'center' }}>
                          Confirmed By QC (CB)
                        </td>
                        <td className="qa-color" style={{ textAlign: 'center' }}>
                          Confirmed LAWA Inspection
                        </td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td colSpan={4} style={{ fontVariant: 'small-caps' }}>Adjustment and Conduit Prep</td>
                      </tr>
                      {
                        data.BaseCan !== 'EXISTING' ? (
                          <>
                            <tr>
                              <td style={{ textAlign: 'right' }}>
                                <Label>Base Adjusted and Level</Label>
                              </td>
                              <td style={{ textAlign: 'center' }}>
                                <FormGroup check><Input onChange={handleChange} type="checkbox" name="BaseAdjustedAndLeveled" checked={data.FixtureInstallation.BaseAdjustedAndLeveled} /></FormGroup>
                              </td>
                              <td style={{ textAlign: 'center' }}>
                                <FormGroup check><Input onChange={handleUserConfirm} disabled={isQcDisabled} type="checkbox" name="BaseAdjustedAndLeveledQC" checked={data.FixtureInstallation.BaseAdjustedAndLeveledQC !== ''} /></FormGroup>
                                {displayConfirmedBy(data.FixtureInstallation.BaseAdjustedAndLeveledQC)}
                              </td>
                              <td />
                            </tr>
                            <tr>
                              <td style={{ textAlign: 'right' }}>
                                <Label>Silicone Sealant Applied (if Adjusted)</Label>
                              </td>
                              <td style={{ textAlign: 'center' }}>
                                <FormGroup check><Input onChange={handleChange} type="checkbox" name="SiliconeSealant" checked={data.FixtureInstallation.SiliconeSealant} /></FormGroup>
                              </td>
                              <td style={{ textAlign: 'center' }}>
                                <FormGroup check><Input onChange={handleUserConfirm} disabled={isQcDisabled} type="checkbox" name="SiliconeSealantQC" checked={data.FixtureInstallation.SiliconeSealantQC !== ''} /></FormGroup>
                                {displayConfirmedBy(data.FixtureInstallation.SiliconeSealantQC)}
                              </td>
                              <td />
                            </tr>
                            <tr>
                              <td style={{ textAlign: 'right' }}>
                                <Label>P-606 Installed to Top of Flange (In-Pavement Only)</Label>
                              </td>
                              <td style={{ textAlign: 'center' }}>
                                <FormGroup check><Input onChange={handleChange} type="checkbox" name="P606Installed" checked={data.FixtureInstallation.P606Installed} /></FormGroup>
                              </td>
                              <td style={{ textAlign: 'center' }}>
                                <FormGroup check><Input onChange={handleUserConfirm} disabled={isQcDisabled} type="checkbox" name="P606InstalledQC" checked={data.FixtureInstallation.P606InstalledQC !== ''} /></FormGroup>
                                {displayConfirmedBy(data.FixtureInstallation.P606InstalledQC)}
                              </td>
                              <td />
                            </tr>
                            <tr>
                              <td style={{ textAlign: 'right' }}>
                                <Label>Bell Ends or Bushing Installed on Conduit</Label>
                              </td>
                              <td style={{ textAlign: 'center' }}>
                                <FormGroup check><Input onChange={handleChange} type="checkbox" name="BellsEndsInstalled" checked={data.FixtureInstallation.BellsEndsInstalled} /></FormGroup>
                              </td>
                              <td style={{ textAlign: 'center' }}>
                                <FormGroup check><Input onChange={handleUserConfirm} disabled={isQcDisabled} type="checkbox" name="BellsEndsInstalledQC" checked={data.FixtureInstallation.BellsEndsInstalledQC !== ''} /></FormGroup>
                                {displayConfirmedBy(data.FixtureInstallation.BellsEndsInstalledQC)}
                              </td>
                              <td />
                            </tr>
                            <tr>
                              <td style={{ textAlign: 'right' }}>
                                <Label for="ExtensionHeight">Extension Height</Label>
                              </td>
                              <td style={{ textAlign: 'left' }}>
                                <FormGroup className="mb-3">
                                  <RadioGroup
                                    name="ExtensionHeight"
                                    id="ExtensionHeight"
                                    onChange={(e, f) => {
                                      handleChange(f);
                                    }}
                                    selectedValue={data.FixtureInstallation.ExtensionHeight || ''}
                                  >
                                    <Label><Radio value="3&quot;" name="ExtensionHeight" style={{ transform: 'scale(1.5)', marginRight: '5px' }} />3&quot;</Label><br />
                                    <Label><Radio value="3.25&quot;" name="ExtensionHeight" style={{ transform: 'scale(1.5)', marginRight: '5px' }} />3.25&quot;</Label><br />
                                    <Label><Radio value="3.5&quot;" name="ExtensionHeight" style={{ transform: 'scale(1.5)', marginRight: '5px' }} />3.5&quot;</Label><br />
                                    <Label><Radio value="4&quot;" name="ExtensionHeight" style={{ transform: 'scale(1.5)', marginRight: '5px' }} />4&quot;</Label>
                                  </RadioGroup>
                                </FormGroup>
                              </td>
                              <td />
                              <td />
                            </tr>
                            <tr>
                              <td style={{ textAlign: 'right' }}>
                                <Label for="FlangeType">Flange Type</Label>
                              </td>
                              <td style={{ textAlign: 'left' }}>
                                <FormGroup className="mb-3">
                                  <RadioGroup
                                    name="FlangeType"
                                    id="FlangeType"
                                    onChange={(e, f) => {
                                      handleChange(f);
                                    }}
                                    selectedValue={data.FixtureInstallation.FlangeType || ''}
                                  >
                                    <Label><Radio value="1/4&quot;" name="FlangeType" style={{ transform: 'scale(1.5)', marginRight: '5px' }} />1/4&quot;</Label><br />
                                    <Label><Radio value="3/8&quot;" name="FlangeType" style={{ transform: 'scale(1.5)', marginRight: '5px' }} />3/8&quot;</Label><br />
                                  </RadioGroup>
                                </FormGroup>
                              </td>
                              <td />
                              <td />
                              <td />
                            </tr>
                          </>
                        ) : <></>

                      }
                      <tr>
                        <td colSpan={4} style={{ fontVariant: 'small-caps' }}>Cable And Light Fixture Installation</td>
                      </tr>
                      <tr>
                        <td style={{ textAlign: 'right' }}>
                          <Label>Base Can Interior Clean </Label>
                        </td>
                        <td style={{ textAlign: 'center' }}>
                          <FormGroup check><Input onChange={handleChange} type="checkbox" name="BaseCanInteriorCleaned" checked={data.FixtureInstallation.BaseCanInteriorCleaned} /></FormGroup>
                        </td>
                        <td style={{ textAlign: 'center' }}>
                          <FormGroup check><Input onChange={handleUserConfirm} disabled={isQcDisabled} type="checkbox" name="BaseCanInteriorCleanedQC" checked={data.FixtureInstallation.BaseCanInteriorCleanedQC !== ''} /></FormGroup>
                          {displayConfirmedBy(data.FixtureInstallation.BaseCanInteriorCleanedQC)}
                        </td>
                        <td />
                      </tr>
                      <tr>
                        <td style={{ textAlign: 'right' }}>
                          <Label>Cable Pulled and 3ft Slack Provided</Label>
                        </td>
                        <td style={{ textAlign: 'center' }}>
                          <FormGroup check><Input onChange={handleChange} type="checkbox" name="CablePulled" checked={data.FixtureInstallation.CablePulled} /></FormGroup>
                        </td>
                        <td style={{ textAlign: 'center' }}>
                          <FormGroup check><Input onChange={handleUserConfirm} disabled={isQcDisabled} type="checkbox" name="CablePulledQC" checked={data.FixtureInstallation.CablePulledQC !== ''} /></FormGroup>
                          {displayConfirmedBy(data.FixtureInstallation.CablePulledQC)}
                        </td>
                        <td />
                      </tr>
                      <tr>
                        <td style={{ textAlign: 'right' }}>
                          <Label>Transformer Stand Installed</Label>
                        </td>
                        <td style={{ textAlign: 'center' }}>
                          <FormGroup check><Input onChange={handleChange} type="checkbox" name="TransformerStandInstalled" checked={data.FixtureInstallation.TransformerStandInstalled} /></FormGroup>
                        </td>
                        <td style={{ textAlign: 'center' }}>
                          <FormGroup check><Input onChange={handleUserConfirm} disabled={isQcDisabled} type="checkbox" name="TransformerStandInstalledQC" checked={data.FixtureInstallation.TransformerStandInstalledQC !== ''} /></FormGroup>
                          {displayConfirmedBy(data.FixtureInstallation.TransformerStandInstalledQC)}
                        </td>
                        <td />
                      </tr>
                      <tr>
                        <td style={{ textAlign: 'right' }}>
                          <Label>Ground Wire Attached to Lug &amp; Fixture</Label>
                        </td>
                        <td style={{ textAlign: 'center' }}>
                          <FormGroup check><Input onChange={handleChange} type="checkbox" name="GroundWireAttached" checked={data.FixtureInstallation.GroundWireAttached} /></FormGroup>
                        </td>
                        <td style={{ textAlign: 'center' }}>
                          <FormGroup check><Input onChange={handleUserConfirm} disabled={isQcDisabled} type="checkbox" name="GroundWireAttachedQC" checked={data.FixtureInstallation.GroundWireAttachedQC !== ''} /></FormGroup>
                          {displayConfirmedBy(data.FixtureInstallation.GroundWireAttachedQC)}
                        </td>
                        <td />
                      </tr>
                      <tr>
                        <td style={{ textAlign: 'right' }}>
                          <Label>Connector Kit Installed</Label>
                        </td>
                        <td style={{ textAlign: 'center' }}>
                          <FormGroup check><Input onChange={handleChange} type="checkbox" name="ConnectorKitInstalled" checked={data.FixtureInstallation.ConnectorKitInstalled} /></FormGroup>
                        </td>
                        <td style={{ textAlign: 'center' }}>
                          <FormGroup check><Input onChange={handleUserConfirm} disabled={isQcDisabled} type="checkbox" name="ConnectorKitInstalledQC" checked={data.FixtureInstallation.ConnectorKitInstalledQC !== ''} /></FormGroup>
                          {displayConfirmedBy(data.FixtureInstallation.ConnectorKitInstalledQC)}
                        </td>
                        <td />
                      </tr>
                      <tr>
                        <td style={{ textAlign: 'right' }}>
                          <Label>Transformer Installed and Primary Connections Taped (130C &amp; 88)</Label>
                        </td>
                        <td style={{ textAlign: 'center' }}>
                          <FormGroup check><Input onChange={handleChange} type="checkbox" name="TransformerInstalled" checked={data.FixtureInstallation.TransformerInstalled} /></FormGroup>
                        </td>
                        <td style={{ textAlign: 'center' }}>
                          <FormGroup check><Input onChange={handleUserConfirm} disabled={isQcDisabled} type="checkbox" name="TransformerInstalledQC" checked={data.FixtureInstallation.TransformerInstalledQC !== ''} /></FormGroup>
                          {displayConfirmedBy(data.FixtureInstallation.TransformerInstalledQC)}
                        </td>
                        <td />
                      </tr>
                      <tr>
                        <td style={{ textAlign: 'right' }}>
                          <Label>Light Controller Re-Installed</Label>
                        </td>
                        <td style={{ textAlign: 'center' }}>
                          <FormGroup check><Input onChange={handleChange} type="checkbox" name="LightControllerReInstalled" checked={data.FixtureInstallation.LightControllerReInstalled} /></FormGroup>
                        </td>
                        <td style={{ textAlign: 'center' }}>
                          <FormGroup check><Input onChange={handleUserConfirm} disabled={isQcDisabled} type="checkbox" name="LightControllerReInstalledQC" checked={data.FixtureInstallation.LightControllerReInstalledQC !== ''} /></FormGroup>
                          {displayConfirmedBy(data.FixtureInstallation.LightControllerReInstalledQC)}
                        </td>
                        <td />
                      </tr>
                      <tr>
                        <td style={{ textAlign: 'right' }}>
                          <Label>Circuit Tags Install, Both Sides of Primary Connections</Label>
                        </td>
                        <td style={{ textAlign: 'center' }}>
                          <FormGroup check><Input onChange={handleChange} type="checkbox" name="CircuitTagsInstalled" checked={data.FixtureInstallation.CircuitTagsInstalled} /></FormGroup>
                        </td>
                        <td style={{ textAlign: 'center' }}>
                          <FormGroup check><Input onChange={handleUserConfirm} disabled={isQcDisabled} type="checkbox" name="CircuitTagsInstalledQC" checked={data.FixtureInstallation.CircuitTagsInstalledQC !== ''} /></FormGroup>
                          {displayConfirmedBy(data.FixtureInstallation.CircuitTagsInstalledQC)}
                        </td>
                        <td />
                      </tr>
                      <tr>
                        <td style={{ textAlign: 'right' }}>
                          <Label>Fixture Connected to Secondary and Taped, 88 Only</Label>
                        </td>
                        <td style={{ textAlign: 'center' }}>
                          <FormGroup check><Input onChange={handleChange} type="checkbox" name="FixtureConnectedTo" checked={data.FixtureInstallation.FixtureConnectedTo} /></FormGroup>
                        </td>
                        <td style={{ textAlign: 'center' }}>
                          <FormGroup check><Input onChange={handleUserConfirm} disabled={isQcDisabled} type="checkbox" name="FixtureConnectedToQC" checked={data.FixtureInstallation.FixtureConnectedToQC !== ''} /></FormGroup>
                          {displayConfirmedBy(data.FixtureInstallation.FixtureConnectedToQC)}
                        </td>
                        <td />
                      </tr>

                      <tr>
                        <td colSpan={4}>
                          <FormGroup>
                            <Photos name="PreLawaFinalInspection" label="Pre-Final LAWA Inspection Photos" multiple storageEndpoint={`${match.params.id}/PreFinalLawaInspection`} zipEndpoint="zip" bucket={config.storageBucket} uploadText="Attach Photos" zipText="Download All" folder={`${match.params.id}/PreFinalLawaInspection`} />
                          </FormGroup>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ textAlign: 'right' }}>
                          <Label>L.A.W.A Inspection QA Check</Label>
                        </td>
                        <td colSpan={2} style={{ textAlign: 'center' }} className="bg-dark text-white">
                          <div>Do Not Proceed Until Signed Off</div>
                        </td>
                        <td style={{ textAlign: 'center' }}>
                          <FormGroup check><Input onChange={handleUserConfirm} disabled={isQaDisabled} type="checkbox" name="LAWAInspectionQACheck" checked={data.FixtureInstallation.LAWAInspectionQACheck} /></FormGroup>
                          {displayConfirmedBy(data.FixtureInstallation.LAWAInspectionQACheck)}
                        </td>
                      </tr>
                      <tr>
                        <td style={{ textAlign: 'right' }}>
                          <Label>Install Fixture with Gasket</Label>
                        </td>
                        <td style={{ textAlign: 'center' }}>
                          <FormGroup check><Input onChange={handleChange} type="checkbox" name="InstallFixtureWithGasket" checked={data.FixtureInstallation.InstallFixtureWithGasket} /></FormGroup>
                        </td>
                        <td style={{ textAlign: 'center' }}>
                          <FormGroup check><Input onChange={handleUserConfirm} disabled={isQcDisabled} type="checkbox" name="InstallFixtureWithGasketQC" checked={data.FixtureInstallation.InstallFixtureWithGasketQC !== ''} /></FormGroup>
                          {displayConfirmedBy(data.FixtureInstallation.InstallFixtureWithGasketQC)}
                        </td>
                        <td />
                      </tr>
                      <tr>
                        <td style={{ textAlign: 'right' }}>
                          <Label>Bolt Down Fixture w/ Approved Hardware and Lock Washers</Label>
                        </td>
                        <td style={{ textAlign: 'center' }}>
                          <FormGroup check><Input onChange={handleChange} type="checkbox" name="BoltDownFixture" checked={data.FixtureInstallation.BoltDownFixture} /></FormGroup>
                        </td>
                        <td style={{ textAlign: 'center' }}>
                          <FormGroup check><Input onChange={handleUserConfirm} disabled={isQcDisabled} type="checkbox" name="BoltDownFixtureQC" checked={data.FixtureInstallation.BoltDownFixtureQC !== ''} /></FormGroup>
                          {displayConfirmedBy(data.FixtureInstallation.BoltDownFixtureQC)}
                        </td>
                        <td />
                      </tr>
                      <tr>
                        <td style={{ textAlign: 'right' }}>
                          <Label>Torque Bolts with Calibrated Torque Wrench</Label><br />
                          <sub>Grade 5 coated fastner (28ft/lb)</sub><br />
                          <sub>Stainless steel fastner (18ft/lb)</sub>
                        </td>
                        <td style={{ textAlign: 'center' }}>
                          <FormGroup check><Input onChange={handleChange} type="checkbox" name="TorqueBolts" checked={data.FixtureInstallation.TorqueBolts} /></FormGroup>
                        </td>
                        <td style={{ textAlign: 'center' }}>
                          <FormGroup check><Input onChange={handleUserConfirm} disabled={isQcDisabled} type="checkbox" name="TorqueBoltsQC" checked={data.FixtureInstallation.TorqueBoltsQC !== ''} /></FormGroup>
                          {displayConfirmedBy(data.FixtureInstallation.TorqueBoltsQC)}
                        </td>
                        <td />
                      </tr>
                      <tr>
                        <td style={{ textAlign: 'right' }}>
                          <Label>Mark Fixture Complete</Label>
                        </td>
                        <td style={{ textAlign: 'center' }}>
                          <FormGroup check>
                            <Label>
                              <Input onChange={handleMarkComplete} type="checkbox" name="MarkFixtureComplete" checked={data.FixtureInstallation.MarkFixtureComplete} />
                              <br />
                            </Label>
                          </FormGroup>
                          <FormGroup>
                            <Input type="text" name="MarkFixtureCompleteName" value={data.FixtureInstallation.MarkFixtureCompleteName} readOnly />
                            <Input type="text" name="MarkFixtureCompleteDate" value={data.FixtureInstallation.MarkFixtureCompleteDate} readOnly />
                          </FormGroup>
                        </td>
                        <td />
                        <td />
                      </tr>
                      <tr>
                        <td colSpan={4}>
                          <FormGroup>
                            <Photos name="FinalInspection" label="Final Inspection Photos" multiple storageEndpoint={`${match.params.id}/FinalInspection`} zipEndpoint="zip" bucket={config.storageBucket} uploadText="Attach Photos" zipText="Download All" folder={`${match.params.id}/FinalInspection`} />
                          </FormGroup>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ textAlign: 'right' }}>
                          <Label>Final Inspection</Label>
                        </td>
                        <td style={{ textAlign: 'center' }}>
                          <FormGroup check><Input onChange={handleUserConfirm} type="checkbox" name="FinalInspectionUser" checked={data.FixtureInstallation.FinalInspectionUser} /></FormGroup>
                          {displayConfirmedBy(data.FixtureInstallation.FinalInspectionUser)}
                        </td>
                        <td style={{ textAlign: 'center' }}>
                          <FormGroup check><Input onChange={handleUserConfirm} disabled={isQcDisabled} type="checkbox" name="FinalInspectionQC" checked={data.FixtureInstallation.FinalInspectionQC !== ''} /></FormGroup>
                          {displayConfirmedBy(data.FixtureInstallation.FinalInspectionQC)}
                        </td>
                        <td style={{ textAlign: 'center' }}>
                          <FormGroup check><Input onChange={handleUserConfirm} disabled={isQaDisabled} type="checkbox" name="FinalInspectionQA" checked={data.FixtureInstallation.FinalInspectionQA} /></FormGroup>
                          {displayConfirmedBy(data.FixtureInstallation.FinalInspectionQA)}
                        </td>
                      </tr>
                      <tr>
                        <td style={{ textAlign: 'right' }}>
                          <Label>Final Inspection Date</Label>
                        </td>
                        <td xs={{ size: 9 }} style={{ textAlign: 'center' }}>
                          <Input onChange={handleChange} type="date" name="FinalInspectionDate" checked={data.FixtureInstallation.FinalInspectionDate} />
                        </td>
                        <td />
                        <td />
                      </tr>
                    </tbody>
                  </Table>
                </div>
                <Label for="Notes">Notes</Label>
                <Input type="textarea" id="Notes" name="Notes" onChange={handleChange} value={data.Notes} rows={6} />
              </>
            )
        }
      </Container>
    </>
  );
};

export default FixtureInstallation;
