import PropTypes from 'prop-types';
import React, {
  useEffect, useRef, useState
} from 'react';
import {
  Button,
  CardBody,
  Collapse,
  CardTitle,
  Label,
} from 'reactstrap';
import { useRecoilState } from 'recoil';
import { toast } from 'react-toastify';
import Loader from 'react-loader-spinner';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';

import FixtureTable from './fixtureTable';
import { useFirebase } from '../_helpers/fetch';
import { CurrentFixtureID, CurrentSystem, CurrentBaseCan } from '../_Recoil/atoms';

import Breadcrumbs from '../_GlobalComponents/breadcrumb';
import Search from '../_DataComponents/searchbar';

const FixtureList = (props) => {
  const { history } = props;

  const [fixtures, setFixtures] = useState([]);
  const [filtersOpen, setFiltersOpen] = useState(false);
  const isComponentMounted = useRef(true);

  const [currentfixtureid, SetCurrentFixtureID] = useRecoilState(CurrentFixtureID);
  const [currentsystem, SetCurrentSystem] = useRecoilState(CurrentSystem);
  const [currentbasecan, SetCurrentBaseCan] = useRecoilState(CurrentBaseCan);

  const queryoptions = useRef({
    limit: 100,
    lastDoc: null,
    sort: null,
    direction: null,
    fixtureid: (currentfixtureid !== -1) ? currentfixtureid : null,
    system: (currentsystem !== -1) ? currentsystem : null
  });

  const idquery = {
    name: 'fixtureIDs',
    options: {
      sort: 'FixtureID'
    },
    isMounted: isComponentMounted,
    initData: [],
    mergeField: 'FixtureID'
  };
  const systemquery = {
    name: 'fixtureSystem',
    options: {
      sort: 'System'
    },
    isMounted: isComponentMounted,
    initData: [],
    mergeField: 'System'
  };
  const basecanquery = {
    name: 'fixtureBaseCan',
    options: {
      sort: 'BaseCan'
    },
    isMounted: isComponentMounted,
    initData: [],
    mergeField: 'BaseCan'
  };

  const {
    data: fixtureListData,
    loading: fixtureListLoading,
    error: fixtureListError,
    refetch: fixtureListRefetch,
    fetchMore: fixtureListFetchMore,
    firebaseCall: fixtureListCall
  } = useFirebase('fixturesList', queryoptions.current, isComponentMounted, [], 'data.id');

  useEffect(() => {
    fixtureListCall();
  }, []);

  useEffect(() => {
    if (typeof fixtureListError !== 'undefined') {
      // eslint-disable-next-line max-len
      toast.error(
        <div>
          Failed to GET Fixtures.
          <br />Error Message: &quot;{fixtureListError}&quot;.
        </div>, { autoClose: 8000 }
      );
      return;
    }
    setFixtures(fixtureListData);
    if (fixtureListData.length > 0) {
      queryoptions.current.lastDoc = fixtureListData[fixtureListData.length - 1].lastDoc;
    }
    else {
      queryoptions.current.lastDoc = null;
    }
  }, [fixtureListData, fixtureListError]);

  const changeOptions = (field) => {
    const queryOptions = { ...queryoptions };
    if (field === queryOptions.current.sort) {
      switch (queryOptions.current.direction.toLowerCase()) {
        case 'asc':
          queryOptions.current.sort = field;
          queryOptions.current.lastDoc = null;
          queryOptions.current.direction = 'DESC';
          break;
        case 'desc':
        default:
          queryOptions.current.sort = field;
          queryOptions.current.lastDoc = null;
          queryOptions.current.direction = 'ASC';
          break;
      }
    }
    else {
      queryOptions.current.sort = field;
      queryOptions.current.direction = 'ASC';
      queryOptions.current.lastDoc = null;
    }

    fixtureListRefetch(queryOptions.current);
  };
  const loadMoreFixtures = () => {
    const queryOptions = { ...queryoptions };
    fixtureListFetchMore(queryOptions.current);
  };

  const filterid = (obj) => {
    const searchval = (typeof obj !== 'undefined' && obj.length > 0 && typeof obj[0].FixtureID !== 'undefined') ? obj[0].FixtureID : -1;
    SetCurrentFixtureID(searchval);
    queryoptions.current.limit = 100;
    queryoptions.current.offset = 0;
    queryoptions.current.sort = null;
    queryoptions.current.direction = null;
    queryoptions.current.lastDoc = null;
    queryoptions.current.fixtureid = searchval === -1 ? null : searchval;
    queryoptions.current.system = currentsystem === -1 ? null : currentsystem;
    fixtureListRefetch();
  };
  const filtersystem = (obj) => {
    const searchval = (typeof obj !== 'undefined' && obj.length > 0 && typeof obj[0].System !== 'undefined') ? obj[0].System : -1;
    SetCurrentSystem(searchval);

    queryoptions.current.limit = 100;
    queryoptions.current.offset = 0;
    queryoptions.current.sort = null;
    queryoptions.current.direction = null;
    queryoptions.current.lastDoc = null;
    queryoptions.current.fixtureid = currentfixtureid === -1 ? null : currentfixtureid;
    queryoptions.current.system = searchval === -1 ? null : searchval;

    fixtureListRefetch();
  };
  const filterbasecan = (obj) => {
    const searchval = (typeof obj !== 'undefined' && obj.length > 0 && typeof obj[0].BaseCan !== 'undefined') ? obj[0].BaseCan : -1;
    SetCurrentBaseCan(searchval);

    queryoptions.current.limit = 100;
    queryoptions.current.offset = 0;
    queryoptions.current.sort = null;
    queryoptions.current.direction = null;
    queryoptions.current.lastDoc = null;
    queryoptions.current.fixtureid = currentfixtureid === -1 ? null : currentfixtureid;
    queryoptions.current.system = currentsystem === -1 ? null : currentsystem;
    queryoptions.current.basecan = searchval === -1 ? null : searchval;

    fixtureListRefetch();
  };

  const handleEdit = (page, args) => {
    history.push(`/${page}/${args.data.id}`, args.data);
  };

  return (
    <>
      <Breadcrumbs path={[{ name: 'Fixtures', active: false }]} />
      <div style={{
        display: 'flex', flexDirection: 'column', justifyContent: 'space-between', flexWrap: 'wrap'
      }}
      >
        <CardTitle>Fixtures</CardTitle>
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          justifyContent: 'space-between'
        }}
        >
          <Button color="warning" style={{ marginRight: '0.3rem' }} onClick={() => { setFiltersOpen(!filtersOpen); }}>Filters</Button>
        </div>
      </div>
      <div style={{ padding: '0 10px' }}>
        <Collapse isOpen={filtersOpen}>
          <CardBody>
            <Label>ID </Label>
            <Search
              type="Fixture ID"
              query={idquery}
              searchfnc={filterid}
              dataprop="fixtureid"
              valueKey="FixtureID"
              optionDisplay={['option_FixtureID']}
              value={(currentfixtureid !== -1) ? currentfixtureid : undefined}
            />

            <Label>Fixture System </Label>
            <Search
              type="System"
              query={systemquery}
              searchfnc={filtersystem}
              dataprop="system"
              valueKey="System"
              optionDisplay={['option_System']}
              value={(currentsystem !== '') ? currentsystem : undefined}
            />

            <Label>Base Can </Label>
            <Search
              type="BaseCan"
              query={basecanquery}
              searchfnc={filterbasecan}
              dataprop="basecan"
              valueKey="BaseCan"
              optionDisplay={['option_BaseCan']}
              value={(currentbasecan !== '') ? currentbasecan : undefined}
            />
          </CardBody>
        </Collapse>
        {
          fixtureListLoading
            ? (
              <div style={{
                display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'
              }}
              >
                <Loader
                  type="TailSpin"
                  color="#3b78e7"
                  height={100}
                  width={100}
                />
                <div>Loading Fixtures...</div>
              </div>
            )
            : (
              <FixtureTable
                fixtures={fixtures}
                queryOptions={queryoptions.current}
                headerSelect={changeOptions}
                fetchMore={loadMoreFixtures}
                onSelect={handleEdit}
              />
            )
        }
      </div>
    </>
  );
};

FixtureList.propTypes = {
  history: PropTypes.shape({
    length: PropTypes.number,
    action: PropTypes.string,
    location: PropTypes.objectOf(PropTypes.string, PropTypes.object),
    push: PropTypes.func,
    replace: PropTypes.func,
    go: PropTypes.func,
    goBack: PropTypes.func,
    goForward: PropTypes.func,
    block: PropTypes.func
  }),
  match: PropTypes.shape({
    params: PropTypes.objectOf(PropTypes.object),
    isExact: PropTypes.bool,
    path: PropTypes.string,
    url: PropTypes.string
  }),
};
FixtureList.defaultProps = {
  history: {
    push: () => { }
  },
  match: {
    url: ''
  }
};

export default FixtureList;
