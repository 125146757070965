import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/storage';

// eslint-disable-next-line arrow-body-style
const firebaseConfig = (() => {
  // if (window.location.hostname === 'localhost') {
  //   return {
  //     apiKey: 'AIzaSyBCekvlBjU5g0SL1C9i46fAtlJQ5ZIY0io',
  //     authDomain: 'aldg-lax-lights-training.firebaseapp.com',
  //     projectId: 'aldg-lax-lights-training',
  //     storageBucket: 'aldg-lax-lights-training-photos',
  //     messagingSenderId: '517353085409',
  //     appId: '1:517353085409:web:1372a1ba9b83fbd3d85aa0',
  //     measurementId: 'G-8L83CG9PET'
  //   };
  // }
  // if (window.location.hostname === 'aldg-lax-lights-training.web.app') {
  //   return {
  //     apiKey: 'AIzaSyBCekvlBjU5g0SL1C9i46fAtlJQ5ZIY0io',
  //     authDomain: 'aldg-lax-lights-training.firebaseapp.com',
  //     projectId: 'aldg-lax-lights-training',
  //     storageBucket: 'aldg-lax-lights-training-photos',
  //     messagingSenderId: '517353085409',
  //     appId: '1:517353085409:web:1372a1ba9b83fbd3d85aa0',
  //     measurementId: 'G-8L83CG9PET'
  //   };
  // }
  // if (window.location.hostname === 'lax.aldridge.app') {
  //   return {
  //     apiKey: 'AIzaSyCfxsNCfjdD500z8X9NJ-nOuwE_afLf_Ao',
  //     authDomain: 'aldg-lax-lights.firebaseapp.com',
  //     projectId: 'aldg-lax-lights',
  //     storageBucket: 'aldg-lax-lights-photos',
  //     messagingSenderId: '273902983961',
  //     appId: '1:273902983961:web:791a8e4b0208dbc414c388',
  //     measurementId: 'G-W4FM4L20MF'
  //   };
  // }
  // return {};

  return {
    apiKey: 'AIzaSyCfxsNCfjdD500z8X9NJ-nOuwE_afLf_Ao',
    authDomain: 'aldg-lax-lights.firebaseapp.com',
    projectId: 'aldg-lax-lights',
    storageBucket: 'aldg-lax-lights-photos',
    messagingSenderId: '273902983961',
    appId: '1:273902983961:web:791a8e4b0208dbc414c388',
    measurementId: 'G-W4FM4L20MF'
  };
})();
firebase.initializeApp(firebaseConfig);

const GoogleProvider = new firebase.auth.GoogleAuthProvider();
GoogleProvider.setCustomParameters({
  prompt: 'select_account'
});
let confirmCode = null;
let codeSent = null;

const recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
  size: 'invisible',
  callback: (response) => {
    // reCAPTCHA solved, allow signInWithPhoneNumber.
    codeSent = true;
    window.confirmationResult = response;
    // onSignInSubmit();
  }
});

if (window.location.hostname === 'localhost') {
  firebase.functions().useEmulator('localhost', 5001);
}

export const config = firebaseConfig;
export const auth = firebase.auth();
export const firestore = firebase.firestore();
export const functions = firebase.functions();
export const storage = firebase.storage();

export const CONFIRM_CODE = confirmCode;
export const CODE_SENT = codeSent;
export const signInWithGoogle = () => {
  auth.signInWithPopup(GoogleProvider);
};
export const signInWithPhone = (callback) => {
  const phoneNumber = document.getElementById('userPhoneNumberLogIn').value;
  const PHONENUMBER = phoneNumber.length === 10 ? `+1${phoneNumber}` : null;
  if (PHONENUMBER === null) {
    throw new Error('Phone Number needs to be 10 characters long.');
  }
  auth.signInWithPhoneNumber(PHONENUMBER, recaptchaVerifier).then((res) => {
    callback(res);
    confirmCode = res;
  });
};
export const verifyCode = (callback) => {
  const code = document.getElementById('userVerificationCode').value;
  confirmCode.confirm(code).then((res) => {
    callback(res);
  });
};
