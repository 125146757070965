import React, { useState, createContext, useEffect } from 'react';
import { toast } from 'react-toastify';
import { auth, functions } from '../firebase';

export const UserContext = createContext({ user: null });
const UserProvider = (props) => {
  const [user, setUser] = useState(null);
  // eslint-disable-next-line react/prop-types
  const { children } = props;
  useEffect(() => {
    auth.onAuthStateChanged(async () => {
      if (auth.currentUser) {
        auth.currentUser.getIdToken(true).then((idToken) => {
          const checkIfUserExists = functions.httpsCallable('userExists');
          checkIfUserExists({ idToken }).then(async () => {
            const token = await auth.currentUser.getIdTokenResult(true);
            setUser(token.claims);
          }).catch((err) => {
            toast.error(err.message, { autoClose: 8000 });
          });
        });
      }
      else {
        setUser(null);
      }
    });
  }, []);

  return (
    <UserContext.Provider value={user}>
      {children}
    </UserContext.Provider>
  );
};

export default UserProvider;
