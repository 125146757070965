/* eslint-disable max-len */
/* eslint-disable object-curly-newline */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import {
  // Label,
  Input,
  Button,
  // FormGroup,
  // Form,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
  Container,
  Col
} from 'reactstrap';
import {
  signInWithGoogle, signInWithPhone, verifyCode
} from '../firebase';
import GoogleButton from '../_GlobalComponents/googleBtn';

const SignIn = () => {
  const [error] = useState(null);
  const [phoneAttempt, setPhoneAttempt] = useState('');
  // eslint-disable-next-line no-unused-vars
  const [verifyAttempt, setVerifyAttempt] = useState('');
  const signInWithPhoneCallback = (e) => {
    setPhoneAttempt(e);
  };
  const verifyCodeCallback = (e) => {
    setVerifyAttempt(e);
  };
  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
      <h1>Sign In To Use the Application</h1>
      <br />
      <br />
      <br />
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', width: '50%' }}>
        {error !== null && <div>{error}</div>}
        <Container fluid>
          <Row style={{ display: 'flex', justifyContent: 'center', paddingBottom: '30px' }}>
            <GoogleButton onClick={signInWithGoogle} mode="dark" />
          </Row>
          <Row xl="11" style={{ textAlign: 'center' }}>
            {/* <Form style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}> */}
            <Col xl="3" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} className="justify-content-xl-end">
              Phone Number
            </Col>
            <Col xl="4">
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>+1</InputGroupText>
                </InputGroupAddon>
                <Input type="text" id="userPhoneNumberLogIn" />
              </InputGroup>
            </Col>
            <Col xl="4">
              <Button type="button" onClick={() => { signInWithPhone(signInWithPhoneCallback); }}>Request SMS Code</Button>
            </Col>
          </Row>
          <br />
          <Row xl="11" style={{ textAlign: 'center' }}>
            {
              phoneAttempt
                ? (
                  <>
                    <Col xl="3" style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                      Code
                    </Col>
                    <Col xl="4">
                      <Input type="number" id="userVerificationCode" />
                    </Col>
                    <Col xl="4">
                      <Button type="button" onClick={() => { verifyCode(verifyCodeCallback); }}>Verify Code</Button>
                    </Col>
                  </>
                )
                : <></>
            }
          </Row>

        </Container>
      </div>
    </div>
  );
};
export default SignIn;
