import React, { useContext } from 'react';
import { Switch, Route } from 'react-router-dom';

import { UserContext } from '../providers/UserProvider';

// import { createBrowserHistory } from 'history';
import SignIn from './SignIn';
import Unauthorized from './Unauthorized';
import NoMatch from './NoMatch';
import FixtureList from './FixtureList';
import FixtureDemo from './FixtureDemo';
import CanInstallation from './CanInstallation';
import FixtureInstallation from './FixtureInstallation';
import Notes from './Notes';
import PDFOutput from './PDFOutput';

const Application = () => {
  const user = useContext(UserContext);
  // const history = createBrowserHistory();
  // const status = (() => {
  //   if (user) {
  //     if (user.admin) return 'ADMIN';
  //     if (user.user) return 'USER';
  //     return 'NOT AUTHORIZED';
  //   }
  //   return 'NOT SIGNED IN';
  // })();
  const page = () => {
    if (!user) {
      return <SignIn />;
    }
    if (user && (user.admin || user.user)) {
      return (
        <Switch>
          <Route exact path="/" component={FixtureList} />
          <Route exact path="/fixtures" component={FixtureList} />
          <Route exact path="/demo/:id" component={FixtureDemo} />
          <Route exact path="/can/:id" component={CanInstallation} />
          <Route exact path="/fixture/:id" component={FixtureInstallation} />
          <Route exact path="/notes/:id" component={Notes} />
          <Route exact path="/pdf/:id" component={PDFOutput} />
          <Route exact><NoMatch /></Route>
        </Switch>
      );
    }

    return <Unauthorized />;
  };

  return (
    <>
      {/* <>{status}</> */}
      <div className="appBody">
        {
          page()
        }
      </div>
    </>
  );
};
export default Application;
