/* eslint-disable max-len */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import '@aldridge/aldg-pdf/dist/index.css';
import { LaxPdf } from '@aldridge/aldg-pdf';
import {
  Col,
  Container,
  Row,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  FormText
} from 'reactstrap';
import html2pdf from 'html2pdf.js';
// import { logo, cl } from './Images';
import logo from '../images/aldgLogo.png';
import cl from '../images/cl.png';

import { useFirebase, useFirebaseMutation } from '../_helpers/fetch';

const sectionPadding = '10px';

const PDFOutput = (props) => {
  const { match } = props;
  const isComponentMounted = useRef(null);
  const [transaction, setTransaction] = useState({
    FixtureID: '',
    Circuit: '',
    FixtureType: '',
    Notes: '',
    LastModifiedBy: '',
    LastModifiedDate: '',
    CanInstallation: {
      lastModifiedBy: '',
      lastModifiedDate: '',
      BaseCompacted: '',
      HolesDrilledAndCleaned: false,
      DowelsEpoxied: false,
      GroundRodInstalled: false,
      GroundResistance: false,
      CounterpoisePresent: false,
      CounterpoiseSize: '',
      ConduitPreppedForConnection: false,
      InstallRebarCageWithGround: false,
      CadweldGround: false,
      SetBasecan: false,
      ConfirmBasecan: false,
      LevelBasecan: false,
      PrePourApproval: ''
    },
    DemoChecklist: {
      lastModifiedBy: '',
      lastModifiedDate: '',
      BoltLength: '',
      BrokenBolts: '',
      NumberBrokenBolts: '',
      ControllerSN: '',
      NumberPipesA: '',
      ElevationGradeA1: '',
      ElevationGradeA2: '',
      ElevationGradeA3: '',
      NumberPipesB: '',
      ElevationGradeB1: '',
      ElevationGradeB2: '',
      ElevationGradeB3: '',
      NumberPipesC: '',
      ElevationGradeC1: '',
      ElevationGradeC2: '',
      ElevationGradeC3: '',
      NumberPipesD: '',
      ElevationGradeD1: '',
      ElevationGradeD2: '',
      ElevationGradeD3: '',
      Position: ''
    },
    FixtureInstallation: {
      ExtensionHeight: '',
      FlangeType: '',
      lastModifiedBy: '',
      lastModifiedDate: '',
      BaseAdjustedAndLeveled: false,
      BaseAdjustedAndLeveledQC: '',
      SiliconeSealant: false,
      SiliconeSealantQC: '',
      P606Installed: false,
      P606InstalledQC: '',
      BellsEndsInstalled: false,
      BellsEndsInstalledQC: '',
      BaseCanInteriorCleaned: false,
      BaseCanInteriorCleanedQC: '',
      CablePulled: false,
      CablePulledQC: '',
      TransformerStandInstalled: false,
      TransformerStandInstalledQC: '',
      GroundWireAttached: false,
      GroundWireAttachedQC: '',
      ConnectorKitInstalled: false,
      ConnectorKitInstalledQC: '',
      TransformerInstalled: false,
      TransformerInstalledQC: '',
      LightControllerReInstalled: false,
      LightControllerReInstalledQC: '',
      CircuitTagsInstalled: false,
      CircuitTagsInstalledQC: '',
      FixtureConnectedTo: false,
      FixtureConnectedToQC: '',

      LAWAInspectionQACheck: '',

      InstallFixtureWithGasket: false,
      InstallFixtureWithGasketQC: '',
      BoltDownFixture: false,
      BoltDownFixtureQC: '',
      TorqueBolts: false,
      TorqueBoltsQC: '',

      MarkFixtureComplete: false,
      MarkFixtureCompleteName: '',
      MarkFixtureCompleteDate: '',

      FinalInspectionUser: '',
      FinalInspectionQC: '',
      FinalInspectionQA: '',
      FinalInspectionDate: ''
    },
    _NextFixture: {
      id: '',
      fixtureID: ''
    },
    _PreviousFixture: {
      id: '',
      fixtureID: ''
    }
  });
  const {
    data: fixtureData,
    loading: fixtureLoading,
    error: fixtureError,
    firebaseCall: fixtureCall,
    refetch: newFixtureCall
  } = useFirebase('getFixtureData', { id: match.params.id }, isComponentMounted, [], 'id');
  const {
    data: PrePourPhotos,
    loading: PrePourPhotosLoading,
    error: PrePourPhotosError,
    firebaseCall: PrePourPhotosCall,
    refetch: newPrePourPhotosCall
  } = useFirebase('getFiles', { path: `${match.params.id}/PrePourPhoto` }, isComponentMounted, [], 'id');
  const {
    data: PreCloseUp,
    loading: PreCloseUpLoading,
    error: PreCloseUpError,
    firebaseCall: PreCloseUpCall,
    refetch: newPreCloseUpCall
  } = useFirebase('getFiles', { path: `${match.params.id}/PreFinalLawaInspection` }, isComponentMounted, [], 'id');
  const {
    data: Final,
    loading: FinalLoading,
    error: FinalError,
    firebaseCall: FinalCall,
    refetch: newFinalCall
  } = useFirebase('getFiles', { path: `${match.params.id}/FinalInspection` }, isComponentMounted, [], 'id');
  const ref = useRef();

  useEffect(() => {
    newFixtureCall({ id: match.params.id });
    newPrePourPhotosCall();
    newPreCloseUpCall();
    newFinalCall();
  }, [match.params.id]);

  useEffect(() => {
    const updatedTransaction = { ...transaction };
    updatedTransaction.Photos = updatedTransaction.Photos || [];
    updatedTransaction.Photos.Prepour = PrePourPhotos.map((res) => ({ Url: res.url }));
    updatedTransaction.Photos.Closeup = PreCloseUp.map((res) => ({ Url: res.url }));
    updatedTransaction.Photos.Final = Final.map((res) => ({ Url: res.url }));
    setTransaction((t) => ({ ...t, ...updatedTransaction }));
  }, [PrePourPhotos, PreCloseUp, Final]);

  useEffect(() => {
    if (typeof fixtureData === 'undefined') { return; }
    if (fixtureData.length > 0) {
      setTransaction(fixtureData[0]);
    }
  }, [fixtureData]);

  const click = () => {
    const opt = {
      margin: 1,
      filename: `${transaction.System}_${transaction.FixtureID}.pdf`,
      html2canvas: { useCORS: true },
      image: { type: 'png', quality: 0.95 }
    };
    const f = html2pdf().from(ref.current).set(opt).save();
  };
  return (
    <>
      <div style={{
        display: 'flex', width: '100%', justifyContent: 'space-between', position: 'sticky', zIndex: '100', top: '0', backgroundColor: 'white'
      }}
      >
        <div style={{ display: 'flex' }}>
          <Button><Link to={`/demo/${transaction.id}`} style={{ textDecoration: 'none', color: 'inherit' }}>Demo Checklist</Link></Button>
          &nbsp;
          {transaction.BaseCan !== 'EXISTING' ? <Button><Link to={`/can/${transaction.id}`} style={{ textDecoration: 'none', color: 'inherit' }}>Can Installation</Link></Button> : null}
          &nbsp;
          <Button><Link to={`/fixture/${transaction.id}`} style={{ textDecoration: 'none', color: 'inherit' }}>Fixture Installation</Link></Button>
        </div>
        <div>
          {transaction._PreviousFixture.id !== '' ? <Button outline color="info"><Link style={{ textDecoration: 'none', color: 'inherit' }} to={`/can/${transaction._PreviousFixture.id}`}>&larr; {transaction._PreviousFixture.fixtureID}</Link></Button> : null}
          &nbsp;&nbsp;&nbsp;
          {transaction._NextFixture.id !== '' ? <Button outline color="info"><Link style={{ textDecoration: 'none', color: 'inherit' }} to={`/can/${transaction._NextFixture.id}`}>{transaction._NextFixture.fixtureID} &rarr;</Link></Button> : null}
        </div>
        <div>
          <Button onClick={click}>Download PDF</Button>
        </div>
      </div>

      <LaxPdf ref={ref} doc={transaction} />
    </>
  );
};

export default PDFOutput;
