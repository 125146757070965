import { useState, useEffect, useRef } from 'react';
import _ from 'lodash';
import { functions, auth } from '../firebase';

const processOptions = (options) => {
  const opts = {};

  if (typeof options !== 'undefined') {
    opts.headers = (typeof options.headers !== 'undefined') ? options.headers : {};
    opts.body = (typeof options.body !== 'undefined') ? options.body : null;
    opts.redirect = (typeof options.redirect !== 'undefined') ? options.redirect : 'follow';
    opts.signal = (typeof options.signal !== 'undefined') ? options.signal : null;
    opts.follow = (typeof options.follow !== 'undefined') ? options.follow : 20;
    opts.timeout = (typeof options.timeout !== 'undefined') ? options.timeout : 0;
    opts.compress = (typeof options.compress !== 'undefined') ? options.compress : true;
    opts.size = (typeof options.size !== 'undefined') ? options.size : 0;
    opts.agent = (typeof options.agent !== 'undefined') ? options.agent : null;
    opts.method = (typeof options.method !== 'undefined') ? options.method : null;
  }

  return opts;
};

const GET = async (url, options) => {
  const opts = processOptions(options);
  opts.method = 'GET';
  const returnobj = {};

  try {
    const resp = await fetch(url, opts);
    if (resp.status >= 200 && resp.status < 300) {
      returnobj.status = 'Success';
      returnobj.response = (await resp.json()).data;
    }
    else {
      returnobj.status = 'Error';
      returnobj.response = (await resp.json()).error;
    }
    return returnobj;
  }
  catch (err) {
    return { status: 'Error', response: err };
  }
};
const POST = async (url, options) => {
  const returnobj = {};
  const opts = processOptions(options);
  if (opts.method !== 'POST') return { status: 'Error', response: `Cannot use ${opts.method} with POST function.` };

  try {
    const resp = await fetch(url, opts);
    if (resp.status >= 200 && resp.status < 300) {
      returnobj.status = 'Success';
      returnobj.response = await resp.json();
    }
    else {
      returnobj.status = 'Error';
      returnobj.response = (await resp.json()).message;
    }
    return returnobj;
  }
  catch (err) {
    return { status: 'Error', response: err };
  }
};
const PATCH = async (url, options) => {
  const returnobj = {};
  const opts = processOptions(options);
  if (opts.method !== 'PATCH') return { status: 'Error', response: `Cannot use ${opts.method} with PATCH function.` };

  try {
    const resp = await fetch(url, opts);
    if (resp.status >= 200 && resp.status < 300) {
      returnobj.status = 'Success';
      returnobj.response = await resp.json();
    }
    else {
      returnobj.status = 'Error';
      returnobj.response = (await resp.json()).error;
    }
    return returnobj;
  }
  catch (err) {
    return { status: 'Error', response: err };
  }
};
const DELETE = async (url, options) => {
  const returnobj = {};
  const opts = processOptions(options);
  if (opts.method !== 'DELETE') return { status: 'Error', response: `Cannot use ${opts.method} with DELETE function.` };

  try {
    const resp = await fetch(url, opts);
    if (resp.status >= 200 && resp.status < 300) {
      returnobj.status = 'Success';
      returnobj.response = await resp.json();
    }
    else {
      returnobj.status = 'Error';
      returnobj.response = (await resp.json()).error;
    }
    return returnobj;
  }
  catch (err) {
    return { status: 'error', response: err };
  }
};

const useQuery = (url, options, ref, initialValue, mergeField) => {
  const [data, setData] = useState(initialValue);
  const [error, setError] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const cache = useRef({});
  let variables = (typeof options.variables !== 'undefined') ? options.variables : {};

  const getQueryString = (queryvars) => {
    const UP = [];
    Object.entries(queryvars).forEach((entry) => {
      const [key, value] = entry;
      UP.push(`${key}=${value}`);
    });
    return UP.join('&');
  };

  const fetchData = (uri, refetch) => {
    (async () => {
      try {
        const qs = getQueryString(variables);
        const connector = (qs === '') ? '' : '?';
        const finalUri = `${uri + connector + qs}`;
        const res = await GET(finalUri, processOptions(options));
        switch (res.status.toLowerCase()) {
          case 'success':
            cache.current[uri] = (refetch)
              ? res.response
              : _.unionBy(cache.current[uri], res.response, mergeField);
            setData(cache.current[uri]);
            break;
          case 'error':
            setError(res.response);
            break;
          default:
        }
      }
      catch (err) {
        setError(err.message);
      }
      finally {
        setLoading(false);
      }
    })();
  };
  const refetch = () => {
    fetchData(url, true);
  };
  const fetchMore = (v) => {
    variables = v;
    fetchData(url);
  };

  useEffect(() => {
    if (!url) return;
    if (ref.current) {
      if (cache.current[url]) {
        const cachedata = cache.current[url];
        setData(cachedata);
        setLoading(false);
      }
      else {
        fetchData(url);
      }
    }
  }, [url, options.variables, ref]);

  // return { loading, data, error };
  return {
    loading, data, error, fetchMore, refetch, cache
  };
};

const useMutation = (url) => {
  const data = useRef([]);
  // const [data, setData] = useState([]);
  const [error, setError] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const uri = (url.charAt(url.length - 1) === '/' ? url.slice(0, -1) : url);

  const mutateData = async (options) => {
    const opts = processOptions(options);
    setLoading(true);
    try {
      switch (opts.method.toUpperCase()) {
        case 'POST':
          return POST(uri, opts);
        // setData(await POST(uri, opts));
        // break;
        case 'PATCH':
          return PATCH(`${uri}/${options.variables.id}`, opts);
        // setData(await PATCH(`${uri}/${options.variables.id}`, opts));
        // break;
        case 'DELETE':
          return DELETE(`${uri}/${options.variables.id}`, opts);
        // setData(await DELETE(`${uri}/${options.variables.id}`, opts));
        // break;
        default:
          break;
      }
    }
    catch (err) {
      setError(err.message);
    }
    setLoading(false);
    return null;
  };

  return {
    mutateData, loading, data, error
  };
};

const useFirebase = (funcName, options, ref, initialValue, mergeField) => {
  const [data, setData] = useState(initialValue);
  const [error, setError] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const cache = useRef({});
  const firebaseFunction = functions.httpsCallable(funcName);
  let variables = (typeof options !== 'undefined') ? options : {};

  const dataCall = (rf) => {
    auth.currentUser.getIdToken(true).then((idToken) => {
      const opts = { ...variables, idToken, host: window.location.hostname };
      firebaseFunction(opts).then((res) => {
        setLoading(false);
        switch (res.data.status) {
          case 'Success':
            cache.current[funcName] = (rf)
              ? res.data.message
              : _.unionBy(cache.current[funcName], res.data.message, mergeField);
            setData(cache.current[funcName]);
            break;
          case 'Error':
            setError(res.data.message);
            break;
          default:
            setError(JSON.stringify(res));
            break;
        }
      }).catch((err) => {
        setError(err.message);
      });
    }).catch((err) => {
      setError(err.message);
    });
  };

  const firebaseCall = () => {
    setError(undefined);
    setLoading(true);
    if (ref.current) {
      if (cache.current[funcName]) {
        const cachedata = cache.current[funcName];
        setLoading(false);
        setData(cachedata);
      }
      else {
        dataCall();
      }
    }
  };

  const refetch = () => {
    dataCall(true);
  };
  const fetchMore = (v) => {
    variables = v;
    dataCall();
  };

  return {
    data, loading, error, refetch, fetchMore, firebaseCall
  };
};

const useFirebaseMutation = (funcName, options) => {
  const [data, setData] = useState(undefined);
  const [error, setError] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const firebaseFunction = functions.httpsCallable(funcName);
  const variables = (typeof options !== 'undefined') ? options : {};

  const firebaseCall = (body) => {
    setData(undefined);
    setLoading(true);
    setError(undefined);
    const dataCall = (b) => {
      auth.currentUser.getIdToken(true).then((idToken) => {
        const opts = { ...variables, body: b, idToken };
        firebaseFunction(opts).then((res) => {
          setLoading(false);
          switch (res.data.status) {
            case 'Success':
              setData(res.data.message);
              break;
            case 'Error':
              setError(res.data.message);
              break;
            default:
              setError(JSON.stringify(res));
              break;
          }
        }).catch((err) => {
          setError(err.message);
        });
      }).catch((err) => {
        setError(err.message);
      });
    };

    dataCall(body);
  };

  return {
    data, loading, error, firebaseCall
  };
};

export {
  GET,
  POST,
  PATCH,
  DELETE,
  useQuery,
  useMutation,
  useFirebase,
  useFirebaseMutation
};
