import { RecoilRoot } from 'recoil';

import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import Loader from 'react-loaders';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import './_assets/base.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
// eslint-disable-next-line import/extensions
import 'bootstrap/dist/js/bootstrap.js';
import configureStore from './_config/configureStore';

import Header from './_GlobalComponents/header';
import Footer from './_GlobalComponents/footer';
import Application from './Pages/Application';
import UserProvider from './providers/UserProvider';

const store = configureStore();
const rootElement = document.getElementById('root');

const renderApp = () => {
  ReactDOM.render(
    <Provider store={store}>
      <UserProvider>
        <BrowserRouter>
          <RecoilRoot>
            <ToastContainer
              position="bottom-left"
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
            <Suspense fallback={
              (
                <div className="loader-container">
                  <div className="loader-container-inner">
                    <div className="text-center">
                      <Loader type="ball-grid-cy" />
                    </div>
                    <h6 className="mt-3">Please wait while we load the LAX Fixtures Application</h6>
                  </div>
                </div>
              )
            }
            >
              <div
                id="appParent"
                style={
                  {
                    display: 'flex',
                    height: '100%',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'center'
                  }
                }
              >
                <Header appTitle="LAX Light Fixures" />
                <Application />
                <Footer version="1.4.1" copy={`${new Date().getFullYear()} Aldridge, All Rights Reserved`} />
              </div>
            </Suspense>
          </RecoilRoot>
        </BrowserRouter>
      </UserProvider>
    </Provider>,
    rootElement
  );
};

renderApp();
