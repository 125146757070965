/* eslint-disable no-underscore-dangle */
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import React, {
  useState, useEffect, useRef, useContext
} from 'react';
import {
  Button,
  Col,
  Row,
  Form,
  FormGroup,
  Label,
  Input,
  CardTitle,
  Container
} from 'reactstrap';
// import { useRecoilValue, useSetRecoilState } from 'recoil';
import { toast } from 'react-toastify';
import { RadioGroup, Radio } from 'react-radio-group';
import Loader from 'react-loader-spinner';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import { UserContext } from '../providers/UserProvider';

import { useFirebase, useFirebaseMutation } from '../_helpers/fetch';

import Breadcrumbs from '../_GlobalComponents/breadcrumb';
import Photos from '../_DataComponents/photos';
import { config } from '../firebase';

const FixtureDemo = (props) => {
  const { match } = props;
  const user = useContext(UserContext);
  const [position, setPosition] = useState(1);
  const [fixture, setFixture] = useState({
    id: null,
    BaseCan: '',
    BaseCanHeight: '',
    CanInstallation: {},
    Circuit: '',
    Notes: '',
    System: '',
    LastModifiedBy: '',
    LastModifiedDate: '',
    DemoChecklist: {
      lastModifiedBy: '',
      lastModifiedDate: '',
      BoltLength: '',
      BrokenBolts: '',
      NumberBrokenBolts: '',
      ControllerSN: '',
      NumberPipesA: '',
      ElevationGradeA1: '',
      ElevationGradeA2: '',
      ElevationGradeA3: '',
      NumberPipesB: '',
      ElevationGradeB1: '',
      ElevationGradeB2: '',
      ElevationGradeB3: '',
      NumberPipesC: '',
      ElevationGradeC1: '',
      ElevationGradeC2: '',
      ElevationGradeC3: '',
      NumberPipesD: '',
      ElevationGradeD1: '',
      ElevationGradeD2: '',
      ElevationGradeD3: '',
      Position: position
    },
    FixtureID: '',
    FixtureInstallation: {},
    FixtureType: '',
    LensColorEast: '',
    LensColorNorth: '',
    LensColorSouth: '',
    LensColorWest: '',
    LocationEasting: '',
    LocationNorthing: '',
    createdBy: '',
    createdDate: '',
    _NextFixture: {
      id: '',
      fixtureID: ''
    },
    _PreviousFixture: {
      id: '',
      fixtureID: ''
    }
  });

  const [showBrokenBolts, setShowBrokenBolts] = useState(false);
  const [showElevationGrade, setShowElevationGrade] = useState({
    A: {
      NumberOfPipes: fixture.DemoChecklist.NumberPipesA || 0,
      Pipe1: parseInt(fixture.DemoChecklist.NumberPipesA, 10) >= 1,
      Pipe2: parseInt(fixture.DemoChecklist.NumberPipesA, 10) >= 2,
      Pipe3: parseInt(fixture.DemoChecklist.NumberPipesA, 10) >= 3
    },
    B: {
      NumberOfPipes: fixture.DemoChecklist.NumberPipesB || 0,
      Pipe1: parseInt(fixture.DemoChecklist.NumberPipesB, 10) >= 1,
      Pipe2: parseInt(fixture.DemoChecklist.NumberPipesB, 10) >= 2,
      Pipe3: parseInt(fixture.DemoChecklist.NumberPipesB, 10) >= 3
    },
    C: {
      NumberOfPipes: fixture.DemoChecklist.NumberPipesC || 0,
      Pipe1: parseInt(fixture.DemoChecklist.NumberPipesC, 10) >= 1,
      Pipe2: parseInt(fixture.DemoChecklist.NumberPipesC, 10) >= 2,
      Pipe3: parseInt(fixture.DemoChecklist.NumberPipesC, 10) >= 3
    },
    D: {
      NumberOfPipes: fixture.DemoChecklist.NumberPipesD || 0,
      Pipe1: parseInt(fixture.DemoChecklist.NumberPipesD, 10) >= 1,
      Pipe2: parseInt(fixture.DemoChecklist.NumberPipesD, 10) >= 2,
      Pipe3: parseInt(fixture.DemoChecklist.NumberPipesD, 10) >= 3
    }
  });

  const isComponentMounted = useRef(true);
  const {
    firebaseCall: fixtureCall,
    data: fixtureData,
    loading: fixtureLoading,
    refetch: newFixtureCall
    // error: fixtureError,
  } = useFirebase('fixturesList', {
    id: match.params.id,
  }, isComponentMounted, [], 'data.id');

  const {
    data: submitData,
    loading: submitLoading,
    error: submitError,
    firebaseCall: submitCall
  } = useFirebaseMutation('submitFixtureData', { data: fixture });

  const handleChange = (event, name) => {
    try {
      let id;
      let value;
      if (typeof name === 'object' && name !== null) {
        id = name.target.name;
        value = typeof name !== 'undefined' ? event : event.target.value;
      }
      else {
        id = name || event.target.name;
        value = typeof name !== 'undefined' ? event : event.target.value;
      }
      if (id === 'Notes') {
        const changedFixture = { ...fixture, [id]: value };
        changedFixture.DemoChecklist.lastModifiedBy = user.email;
        changedFixture.DemoChecklist.lastModifiedDate = new Date().toJSON();
        changedFixture.LastModifiedBy = user.email;
        changedFixture.LastModifiedDate = new Date().toJSON();
        setFixture(changedFixture);
      }
      else {
        const changedDemo = { ...fixture.DemoChecklist, [id]: value };
        const changedFixture = { ...fixture, DemoChecklist: changedDemo };
        changedFixture.DemoChecklist.lastModifiedBy = user.email;
        changedFixture.DemoChecklist.lastModifiedDate = new Date().toJSON();
        changedFixture.LastModifiedBy = user.email;
        changedFixture.LastModifiedDate = new Date().toJSON();
        setFixture(changedFixture);
      }
    }
    catch (err) {
      toast.error(err.message, { autoClose: 8000 });
    }
  };
  const handleBrokenBoltChange = (event, name) => {
    try {
      const id = name.target.name;
      const value = event;
      const changedBrokenBolts = { ...fixture.DemoChecklist, [id]: value };
      const changedNumberBrokenBolts = { ...changedBrokenBolts, NumberBrokenBolts: 'None' };
      const changedFixture = { ...fixture, DemoChecklist: changedNumberBrokenBolts };
      if (value === 'Yes') {
        setShowBrokenBolts(true);
      }
      else {
        setShowBrokenBolts(false);
      }
      changedFixture.DemoChecklist.lastModifiedBy = user.email;
      changedFixture.DemoChecklist.lastModifiedDate = new Date().toJSON();
      changedFixture.LastModifiedBy = user.email;
      changedFixture.LastModifiedDate = new Date().toJSON();
      setFixture(changedFixture);
    }
    catch (err) {
      toast.error(err.message, { autoClose: 8000 });
    }
  };

  const handleElevationGrades = (event, elevationPosition) => {
    const { name, value } = event.target;

    setShowElevationGrade({ ...showElevationGrade });
    const changedNumberOfPipes = { ...fixture.DemoChecklist, [name]: value };
    const changedElevationGrade = { ...showElevationGrade };
    switch (value.toString()) {
      case '1':
        changedElevationGrade[elevationPosition].Pipe1 = true;
        changedElevationGrade[elevationPosition].Pipe2 = false;
        changedElevationGrade[elevationPosition].Pipe3 = false;
        changedNumberOfPipes[`ElevationGrade${elevationPosition}2`] = '';
        changedNumberOfPipes[`ElevationGrade${elevationPosition}3`] = '';
        break;
      case '2':
        changedElevationGrade[elevationPosition].Pipe1 = true;
        changedElevationGrade[elevationPosition].Pipe2 = true;
        changedElevationGrade[elevationPosition].Pipe3 = false;
        changedNumberOfPipes[`ElevationGrade${elevationPosition}3`] = '';
        break;
      case '3':
        changedElevationGrade[elevationPosition].Pipe1 = true;
        changedElevationGrade[elevationPosition].Pipe2 = true;
        changedElevationGrade[elevationPosition].Pipe3 = true;
        break;
      default:
        changedElevationGrade[elevationPosition].Pipe1 = false;
        changedElevationGrade[elevationPosition].Pipe2 = false;
        changedElevationGrade[elevationPosition].Pipe3 = false;
        changedNumberOfPipes[`ElevationGrade${elevationPosition}1`] = '';
        changedNumberOfPipes[`ElevationGrade${elevationPosition}2`] = '';
        changedNumberOfPipes[`ElevationGrade${elevationPosition}3`] = '';
        break;
    }
    setShowElevationGrade(changedElevationGrade);
    fixture.DemoChecklist.lastModifiedBy = user.email;
    fixture.DemoChecklist.lastModifiedDate = new Date().toJSON();
    fixture.LastModifiedBy = user.email;
    fixture.LastModifiedDate = new Date().toJSON();
    setFixture({ ...fixture, DemoChecklist: changedNumberOfPipes });
  };
  const rotate = () => {
    const half = 150;
    const margin = 75;
    const lnlength = 120;
    const triheight = 40;
    const trilength = triheight / 2;
    const coords = [margin + lnlength, margin + triheight, margin + triheight + lnlength];
    const deg = (parseInt(7, 10) - 1) * 45;

    return (
      <>
        <g style={{ transformBox: 'fill-box', transformOrigin: 'center', transform: `rotate(${deg}deg)` }}>
          <line x1={half} y1={coords[1]} x2={half} y2={coords[2]} style={{ stroke: '#000', strokeWidth: '4' }} />
          <polygon points={`${half},${margin} ${half - trilength},${coords[1]} ${half + trilength},${coords[1]}`} style={{ fill: '#d8262f', stroke: '#000', strokeWidth: '3' }} />
        </g>
      </>
    );
  };

  const submit = () => {
    const finalSet = { ...fixture };
    finalSet.DemoChecklist.lastModifiedBy = user.email;
    finalSet.DemoChecklist.lastModifiedDate = new Date().toJSON();
    finalSet.LastModifiedBy = user.email;
    finalSet.LastModifiedDate = new Date().toJSON();
    setFixture(finalSet);
    submitCall();
  };

  useEffect(() => {
    fixtureCall();
  }, []);
  useEffect(() => {
    newFixtureCall({ id: match.params.id });
  }, [match.params.id]);

  const submitToastId = useRef(null);
  useEffect(() => {
    if (submitLoading) {
      submitToastId.current = toast('Submitting Record', {
        position: 'top-center',
        autoClose: false
      });
    }
    else if (!submitLoading && submitToastId.current !== null) {
      toast.dismiss(submitToastId.current);
    }
  }, [submitLoading]);

  useEffect(() => {
    if (typeof submitError !== 'undefined') {
      toast.error(
        <div>
          Failed to Submit Record:
          <br />
          Error Message: &quot;{submitError}&quot;.
        </div>,
        { autoClose: 8000 }
      );
    }
    if (typeof submitData !== 'undefined') {
      toast.success('Fixure Saved Successfully.', { autoClose: 3000 });
    }
  }, [submitData, submitError]);

  useEffect(() => {
    if (fixtureData.length > 0) {
      setFixture(fixtureData[0].data);
      if (fixtureData[0].data.DemoChecklist.Position !== '') setPosition(parseInt(fixtureData[0].data.DemoChecklist.Position, 10));
    }
  }, [fixtureData]);

  useEffect(() => {
    setShowElevationGrade({
      A: {
        NumberOfPipes: fixture.DemoChecklist.NumberPipesA || 0,
        Pipe1: parseInt(fixture.DemoChecklist.NumberPipesA, 10) >= 1,
        Pipe2: parseInt(fixture.DemoChecklist.NumberPipesA, 10) >= 2,
        Pipe3: parseInt(fixture.DemoChecklist.NumberPipesA, 10) >= 3
      },
      B: {
        NumberOfPipes: fixture.DemoChecklist.NumberPipesB || 0,
        Pipe1: parseInt(fixture.DemoChecklist.NumberPipesB, 10) >= 1,
        Pipe2: parseInt(fixture.DemoChecklist.NumberPipesB, 10) >= 2,
        Pipe3: parseInt(fixture.DemoChecklist.NumberPipesB, 10) >= 3
      },
      C: {
        NumberOfPipes: fixture.DemoChecklist.NumberPipesC || 0,
        Pipe1: parseInt(fixture.DemoChecklist.NumberPipesC, 10) >= 1,
        Pipe2: parseInt(fixture.DemoChecklist.NumberPipesC, 10) >= 2,
        Pipe3: parseInt(fixture.DemoChecklist.NumberPipesC, 10) >= 3
      },
      D: {
        NumberOfPipes: fixture.DemoChecklist.NumberPipesD || 0,
        Pipe1: parseInt(fixture.DemoChecklist.NumberPipesD, 10) >= 1,
        Pipe2: parseInt(fixture.DemoChecklist.NumberPipesD, 10) >= 2,
        Pipe3: parseInt(fixture.DemoChecklist.NumberPipesD, 10) >= 3
      }
    });
    setShowBrokenBolts(fixture.DemoChecklist.BrokenBolts === 'Yes');
  }, [fixture]);
  return (
    <>
      <Breadcrumbs path={[
        { name: 'Fixtures', active: true, link: '/fixtures' },
        { name: `Fixture Demo - ${fixture.FixtureID}`, active: false }
      ]}
      />
      <div style={{
        display: 'flex', width: '100%', justifyContent: 'space-between', position: 'sticky', zIndex: '100', top: '0', backgroundColor: 'white'
      }}
      >
        <div>
          {fixture.BaseCan !== 'EXISTING' ? <Button><Link to={`/can/${fixture.id}`} style={{ textDecoration: 'none', color: 'inherit' }}>Can Installation</Link></Button> : null}
          &nbsp;
          <Button><Link to={`/fixture/${fixture.id}`} style={{ textDecoration: 'none', color: 'inherit' }}>Fixture Installation</Link></Button>
          &nbsp;
          <Button><Link to={`/pdf/${fixture.id}`} style={{ textDecoration: 'none', color: 'inherit' }}>PDF Output</Link></Button>
        </div>
        <div>
          {fixture._PreviousFixture.id !== '' ? <Button outline color="info"><Link style={{ textDecoration: 'none', color: 'inherit' }} to={`/demo/${fixture._PreviousFixture.id}`}>&larr; {fixture._PreviousFixture.fixtureID}</Link></Button> : null}
          &nbsp;&nbsp;&nbsp;
          {fixture._NextFixture.id !== '' ? <Button outline color="info"><Link style={{ textDecoration: 'none', color: 'inherit' }} to={`/demo/${fixture._NextFixture.id}`}>{fixture._NextFixture.fixtureID} &rarr;</Link></Button> : null}
        </div>
        <div>
          <Button className="bg-success" onClick={submit}>Save</Button>
          &nbsp;
          <Button className="bg-danger"><Link to="/" style={{ textDecoration: 'none', color: 'inherit' }}>Cancel</Link></Button>
        </div>
      </div>

      <div>
        {
          submitLoading ? (
            <div style={{
              display: 'block', position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, backgroundColor: '#efefef', opacity: 0.5, zIndex: 100
            }}
            />
          ) : <></>
        }
        <Container fluid>
          {
            fixtureLoading ? (
              <div style={{
                display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'
              }}
              >
                <Loader
                  type="TailSpin"
                  color="#3b78e7"
                  height={150}
                  width={150}
                />
                Loading Fixture Data...
              </div>
            )
              : (
                <Form>
                  <Row>
                    <Col md={{ size: 4 }}>
                      <FormGroup>
                        <Label for="FixtureID">Fixture ID:</Label>
                        <Input type="text" name="FixtureID" value={fixture.FixtureID} readOnly />
                      </FormGroup>
                    </Col>
                    <Col md={{ size: 4 }}>
                      <FormGroup>
                        <Label for="System">System:</Label>
                        <Input type="text" name="System" value={fixture.System} readOnly />
                      </FormGroup>
                    </Col>
                    <Col md={{ size: 4 }}>
                      <FormGroup>
                        <Label for="BaseCan">Base Can:</Label>
                        <Input type="text" name="BaseCan" value={fixture.BaseCan || ''} readOnly />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row form>
                    <Col md={4}>
                      <FormGroup>
                        <Label for="BoltLength">Bolt Length</Label>
                        <Input type="select" name="BoltLength" id="BoltLength" onChange={handleChange} value={fixture.DemoChecklist.BoltLength || ''}>
                          <option>2&quot;</option>
                          <option>2.5&quot;</option>
                          <option>3&quot;</option>
                          <option>3.5&quot;</option>
                          <option>4&quot;</option>
                          <option>4.5&quot;</option>
                          <option>5&quot;</option>
                          <option>5.5&quot;</option>
                          <option>6&quot;</option>
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col md={4}>
                      <FormGroup>
                        <Label for="BrokenBolts">Broken Bolts</Label>
                        <RadioGroup name="BrokenBolts" id="BrokenBolts" onChange={handleBrokenBoltChange} selectedValue={fixture.DemoChecklist.BrokenBolts || ''}>
                          <Label><Radio value="Yes" name="BrokenBolts" style={{ transform: 'scale(1.5)', marginRight: '5px' }} />Yes</Label>
                          <br />
                          <Label><Radio value="No" name="BrokenBolts" style={{ transform: 'scale(1.5)', marginRight: '5px' }} />No</Label>
                        </RadioGroup>
                      </FormGroup>
                    </Col>
                    <Col md={4}>
                      <FormGroup style={{ display: ((showBrokenBolts) ? '' : 'none') }}>
                        <Label for="NumberBrokenBolts">How many broken bolts?</Label>
                        <Input type="select" name="NumberBrokenBolts" id="NumberBrokenBolts" value={parseInt(fixture.DemoChecklist.NumberBrokenBolts, 10) || ''} onChange={handleChange}>
                          <option>None</option>
                          <option>1</option>
                          <option>1</option>
                          <option>2</option>
                          <option>3</option>
                          <option>4</option>
                          <option>5</option>
                          <option>6</option>
                        </Input>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row form>
                    <Col md={6}>
                      <FormGroup>
                        <Photos name="preDemoOutside" label="Pre Demo Outside Photos" multiple storageEndpoint={`${match.params.id}/PreDemoOutside`} zipEndpoint="zip" bucket={config.storageBucket} uploadText="Attach Photos" zipText="Download All" folder={`${match.params.id}/PreDemoOutside`} />
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <Photos name="preDemoInside" label="Pre Demo Inside Photos" multiple storageEndpoint={`${match.params.id}/PreDemoInside`} zipEndpoint="zip" bucket={config.storageBucket} uploadText="Attach Photos" zipText="Download All" folder={`${match.params.id}/PreDemoInside`} />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row form>
                    <Col md={6}>
                      <FormGroup>
                        <Label for="ControllerSN">Controller Serial Number</Label>
                        <Input type="text" name="ControllerSN" id="ControllerSN" onChange={handleChange} value={fixture.DemoChecklist.ControllerSN || ''} />
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <Photos name="ControllerPhoto" label="Controller Photo Photos" multiple storageEndpoint={`${match.params.id}/ControllerPhoto`} zipEndpoint="zip" bucket={config.storageBucket} uploadText="Attach Photos" zipText="Download All" folder={`${match.params.id}/ControllerPhoto`} />
                      </FormGroup>
                    </Col>
                  </Row>
                  <CardTitle>Conduit Locations/Elevations</CardTitle>
                  <Row form>
                    <Col md={6}>
                      <div style={{
                        display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', height: '100%'
                      }}
                      >
                        {/*
                          <Button color="warning"
                          onClick={() => {
                            const newpos = (position === 8) ? 1 : position + 1;
                            setPosition(newpos);
                            handleChange(newpos, 'Position');
                          }}>Rotate 45&#176;</Button> */}
                        <br />
                        <svg height={300} width={300} style={{ transformBox: 'fill-box', transformOrigin: 'center' }}>
                          <circle cx={150} cy={150} r={140} fill="#fff" stroke="#000" strokeWidth="3" />
                          <text x="138" y="140" fill="#000" style={{ font: 'bold 30px sans-serif' }}>C</text>
                          <text x="145" y="150" fill="#000" style={{ font: 'bold 30px sans-serif' }}>L</text>

                          <rect width="15" height="20" x="3" y="140" style={{ fill: '#0000ff', strokeWidth: '3', stroke: '#000' }} />
                          <rect width="20" height="15" x="140" y="3" style={{ fill: '#0000ff', strokeWidth: '3', stroke: '#000' }} />
                          <rect width="15" height="20" x="284" y="140" style={{ fill: '#0000ff', strokeWidth: '3', stroke: '#000' }} />
                          <rect width="20" height="15" x="140" y="284" style={{ fill: '#0000ff', strokeWidth: '3', stroke: '#000' }} />

                          <text x="6" y="154" fill="#fff" style={{ font: 'bold 12px sans-serif' }}>D</text>
                          <text x="146" y="14" fill="#fff" style={{ font: 'bold 12px sans-serif' }}>A</text>
                          <text x="287" y="154" fill="#fff" style={{ font: 'bold 12px sans-serif' }}>B</text>
                          <text x="146" y="296" fill="#fff" style={{ font: 'bold 12px sans-serif' }}>C</text>

                          {rotate()}
                        </svg>
                      </div>
                    </Col>
                    <Col md={6}>
                      <Row>
                        <h4 style={{ width: '100%', borderBottom: '1px solid #ccc', paddingLeft: '5px' }}>A</h4>
                        <Col xs={5}>
                          <FormGroup>
                            <Label for="NumberPipesA">Number of Pipes</Label>
                            <Input type="select" name="NumberPipesA" id="NumberPipesA" onChange={(event) => { handleElevationGrades(event, 'A'); }} value={fixture.DemoChecklist.NumberPipesA || ''}>
                              <option>0</option>
                              <option>1</option>
                              <option>2</option>
                              <option>3</option>
                            </Input>
                          </FormGroup>
                        </Col>
                        <Col xs={7}>
                          <Label for="ElevationGradeA">Elevation from Grade</Label>
                          <FormGroup>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                              <Input type="number" name="ElevationGradeA1" id="ElevationGradeA1" onChange={handleChange} placeholder="Pipe 1" value={fixture.DemoChecklist.ElevationGradeA1 || ''} style={{ display: (showElevationGrade.A.Pipe1) ? '' : 'none' }} />
                              <Input type="number" name="ElevationGradeA2" id="ElevationGradeA2" onChange={handleChange} placeholder="Pipe 2" value={fixture.DemoChecklist.ElevationGradeA2 || ''} style={{ display: (showElevationGrade.A.Pipe2) ? '' : 'none' }} />
                              <Input type="number" name="ElevationGradeA3" id="ElevationGradeA3" onChange={handleChange} placeholder="Pipe 3" value={fixture.DemoChecklist.ElevationGradeA3 || ''} style={{ display: (showElevationGrade.A.Pipe3) ? '' : 'none' }} />
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row>
                        <h4 style={{ width: '100%', borderBottom: '1px solid #ccc', paddingLeft: '5px' }}>B</h4>
                        <Col xs={5}>
                          <FormGroup>
                            <Label for="NumberPipesB">Number of Pipes</Label>
                            <Input type="select" name="NumberPipesB" id="NumberPipesB" onChange={(event) => { handleElevationGrades(event, 'B'); }} value={fixture.DemoChecklist.NumberPipesB || ''}>
                              <option>0</option>
                              <option>1</option>
                              <option>2</option>
                              <option>3</option>
                            </Input>
                          </FormGroup>

                        </Col>
                        <Col xs={7}>
                          <Label for="ElevationGradeB">Elevation from Grade</Label>
                          <FormGroup>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                              <Input type="number" name="ElevationGradeB1" id="ElevationGradeB1" onChange={handleChange} placeholder="Pipe 1" value={fixture.DemoChecklist.ElevationGradeB1 || ''} style={{ display: (showElevationGrade.B.Pipe1) ? '' : 'none' }} />
                              <Input type="number" name="ElevationGradeB2" id="ElevationGradeB2" onChange={handleChange} placeholder="Pipe 2" value={fixture.DemoChecklist.ElevationGradeB2 || ''} style={{ display: (showElevationGrade.B.Pipe2) ? '' : 'none' }} />
                              <Input type="number" name="ElevationGradeB3" id="ElevationGradeB3" onChange={handleChange} placeholder="Pipe 3" value={fixture.DemoChecklist.ElevationGradeB3 || ''} style={{ display: (showElevationGrade.B.Pipe3) ? '' : 'none' }} />
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row>
                        <h4 style={{ width: '100%', borderBottom: '1px solid #ccc', paddingLeft: '5px' }}>C</h4>
                        <Col xs={5}>
                          <FormGroup>
                            <Label for="NumberPipesC">Number of Pipes</Label>
                            <Input type="select" name="NumberPipesC" id="NumberPipesC" onChange={(event) => { handleElevationGrades(event, 'C'); }} value={fixture.DemoChecklist.NumberPipesC || ''}>
                              <option>0</option>
                              <option>1</option>
                              <option>2</option>
                              <option>3</option>
                            </Input>
                          </FormGroup>

                        </Col>
                        <Col xs={7}>
                          <Label for="ElevationGradeC">Elevation from Grade</Label>
                          <FormGroup>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                              <Input type="number" name="ElevationGradeC1" id="ElevationGradeC1" onChange={handleChange} placeholder="Pipe 1" value={fixture.DemoChecklist.ElevationGradeC1 || ''} style={{ display: (showElevationGrade.C.Pipe1) ? '' : 'none' }} />
                              <Input type="number" name="ElevationGradeC2" id="ElevationGradeC2" onChange={handleChange} placeholder="Pipe 2" value={fixture.DemoChecklist.ElevationGradeC2 || ''} style={{ display: (showElevationGrade.C.Pipe2) ? '' : 'none' }} />
                              <Input type="number" name="ElevationGradeC3" id="ElevationGradeC3" onChange={handleChange} placeholder="Pipe 3" value={fixture.DemoChecklist.ElevationGradeC3 || ''} style={{ display: (showElevationGrade.C.Pipe3) ? '' : 'none' }} />
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row>
                        <h4 style={{ width: '100%', borderBottom: '1px solid #ccc', paddingLeft: '5px' }}>D</h4>
                        <Col xs={5}>
                          <FormGroup>
                            <Label for="NumberPipesD">Number of Pipes</Label>
                            <Input type="select" name="NumberPipesD" id="NumberPipesD" onChange={(event) => { handleElevationGrades(event, 'D'); }} value={fixture.DemoChecklist.NumberPipesD || ''}>
                              <option>0</option>
                              <option>1</option>
                              <option>2</option>
                              <option>3</option>
                            </Input>
                          </FormGroup>

                        </Col>
                        <Col xs={7}>
                          <Label for="ElevationGradeD">Elevation from Grade</Label>
                          <FormGroup>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                              <Input type="number" name="ElevationGradeD1" id="ElevationGradeD1" onChange={handleChange} placeholder="Pipe 1" value={fixture.DemoChecklist.ElevationGradeD1 || ''} style={{ display: (showElevationGrade.D.Pipe1) ? '' : 'none' }} />
                              <Input type="number" name="ElevationGradeD2" id="ElevationGradeD2" onChange={handleChange} placeholder="Pipe 2" value={fixture.DemoChecklist.ElevationGradeD2 || ''} style={{ display: (showElevationGrade.D.Pipe2) ? '' : 'none' }} />
                              <Input type="number" name="ElevationGradeD3" id="ElevationGradeD3" onChange={handleChange} placeholder="Pipe 3" value={fixture.DemoChecklist.ElevationGradeD3 || ''} style={{ display: (showElevationGrade.D.Pipe3) ? '' : 'none' }} />
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>

                    </Col>
                  </Row>
                  <FormGroup>
                    <Photos name="DemoPhoto" label="Demo Photos" multiple storageEndpoint={`${match.params.id}/DemoPhoto`} zipEndpoint="zip" bucket={config.storageBucket} uploadText="Attach Photos" zipText="Download All" folder={`${match.params.id}/DemoPhoto`} />
                  </FormGroup>
                  <Row>
                    <Col>
                      <Label for="Notes">Notes</Label>
                      <Input type="textarea" id="Notes" name="Notes" onChange={handleChange} value={fixture.Notes} row={6} />
                    </Col>
                  </Row>
                </Form>

              )
          }
        </Container>
      </div>
    </>
  );
};

FixtureDemo.propTypes = {
  history: PropTypes.shape({
    length: PropTypes.number,
    action: PropTypes.string,
    location: PropTypes.shape({
      state: PropTypes.objectOf(PropTypes.any)
    }),
    // PropTypes.objectOf(PropTypes.string, PropTypes.object),
    push: PropTypes.func,
    replace: PropTypes.func,
    go: PropTypes.func,
    goBack: PropTypes.func,
    goForward: PropTypes.func,
    block: PropTypes.func
  }),
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired
    }),
    isExact: PropTypes.bool,
    path: PropTypes.string,
    url: PropTypes.string
  }),
};
FixtureDemo.defaultProps = {
  history: {
    push: () => { }
  },
  match: {
    url: ''
  }
};

export default FixtureDemo;
