/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React, {
  useState,
  useContext,
  useEffect,
  useRef
} from 'react';
import { Link } from 'react-router-dom';
import {
  Col,
  Container,
  Row,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  FormText
} from 'reactstrap';
import { toast } from 'react-toastify';

import { RadioGroup, Radio } from 'react-radio-group';

import Loader from 'react-loader-spinner';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';

import { UserContext } from '../providers/UserProvider';
import { useFirebase, useFirebaseMutation } from '../_helpers/fetch';
import Breadcrumbs from '../_GlobalComponents/breadcrumb';
import Photos from '../_DataComponents/photos';
import { config } from '../firebase';
import Confirm from '../_GlobalComponents/Confirm';

const CanInstallation = (props) => {
  const { match } = props;
  const user = useContext(UserContext);
  const submitToastId = useRef(null);
  const [data, setData] = useState({
    FixtureID: '',
    Circuit: '',
    FixtureType: '',
    Notes: '',
    LastModifiedBy: '',
    LastModifiedDate: '',
    CanInstallation: {
      lastModifiedBy: '',
      lastModifiedDate: '',
      BaseCompacted: '',
      HolesDrilledAndCleaned: false,
      DowelsEpoxied: false,
      GroundRodInstalled: false,
      GroundResistance: false,
      CounterpoisePresent: false,
      CounterpoiseSize: '',
      ConduitPreppedForConnection: false,
      InstallRebarCageWithGround: false,
      CadweldGround: false,
      SetBasecan: false,
      ConfirmBasecan: false,
      LevelBasecan: false,
      PrePourApproval: ''
    },
    _NextFixture: {
      id: '',
      fixtureID: ''
    },
    _PreviousFixture: {
      id: '',
      fixtureID: ''
    }
  });
  const [approvalState, setApprovalState] = useState({
    proceedLabel: 'Uncheck',
    cancelLabel: 'Cancel',
    title: 'Uncheck Approval',
    modalBody: 'Would you like to uncheck this selection?',
    show: false,
    // eslint-disable-next-line no-use-before-define
    proceed: (approved, r) => { uncheckRec(approved, r); },
    enableEscape: true,
    record: undefined
  });
  const uncheckRec = (approved, record) => {
    const d = record.record;
    const { name } = record;
    if (approved) {
      const rec = { ...d };
      rec.CanInstallation[name] = '';
      rec.CanInstallation.lastModifiedBy = user.email;
      rec.CanInstallation.lastModifiedDate = new Date().toJSON();
      rec.LastModifiedBy = user.email;
      rec.LastModifiedDate = new Date().toJSON();
      setData({ ...rec });
    }
    setApprovalState({ ...approvalState, show: false });
  };
  const handleApproval = (record) => {
    setApprovalState({ ...approvalState, show: true, record });
  };
  const isComponentMounted = useRef(true);
  const {
    data: fixtureData,
    loading: fixtureLoading,
    error: fixtureError,
    firebaseCall: fixtureCall,
    refetch: newFixtureCall
  } = useFirebase('getFixtureData', { id: match.params.id }, isComponentMounted, [], 'id');

  const {
    data: submitData,
    loading: submitLoading,
    error: submitError,
    firebaseCall: submitCall
  } = useFirebaseMutation('submitFixtureData', { data });

  useEffect(() => {
    fixtureCall();
  }, []);
  useEffect(() => {
    newFixtureCall({ id: match.params.id });
  }, [match.params.id]);

  useEffect(() => {
    if (fixtureData.length > 0) {
      // const d = { ...data, fixtureData[0] };
      setData(fixtureData[0]);
    }
  }, [fixtureData]);

  useEffect(() => {
    if (submitLoading) {
      submitToastId.current = toast('Submitting Record', {
        position: 'top-center',
        autoClose: false
      });
    }
    else if (!submitLoading && submitToastId.current !== null) {
      toast.dismiss(submitToastId.current);
    }
  }, [submitLoading]);

  useEffect(() => {
    if (typeof fixtureError !== 'undefined') {
      toast.error(`'Error Loading Fixture Data: ${fixtureError}`, { autoClose: 8000 });
    }
  }, [fixtureError]);
  useEffect(() => {
    if (typeof submitData !== 'undefined') {
      toast.success('Record saved successfully', { autoClose: 2000 });
    }
    if (typeof submitError !== 'undefined') {
      toast.error(`Record Failed to Save: ${submitError}.`, { autoClose: 8000 });
    }
  }, [submitData, submitLoading, submitError]);
  const handleChange = (event, name) => {
    try {
      const d = { ...data };

      if (typeof event.target !== 'undefined' && event.target.type.toLowerCase() === 'checkbox') {
        const id = name || event.target.name;
        d.CanInstallation[id] = event.target.checked;
      }
      else {
        const id = name || event.target.name;
        const value = typeof name !== 'undefined' ? event : event.target.value;
        if (id === 'Notes') {
          d[id] = value;
        }
        else {
          d.CanInstallation[id] = value;
        }
      }
      d.CanInstallation.lastModifiedBy = user.email;
      d.CanInstallation.lastModifiedDate = new Date().toJSON();
      d.LastModifiedBy = user.email;
      d.LastModifiedDate = new Date().toJSON();
      setData({ ...d });
    }
    catch (err) {
      toast.error(err.message, { autoClose: 8000 });
    }
  };
  const submit = () => {
    submitCall();
  };
  const displayConfirmedBy = (dataField) => (dataField !== '' ? <><br /><div>CB: {dataField}</div></> : <></>);
  const handleUserConfirm = (event) => {
    const d = { ...data };
    const display = `${user.name} (${user.email})\n${new Date().toLocaleString('en-US', { timeZone: 'CST', timeZoneName: 'short' })}`;
    if (d.CanInstallation[event.target.name] !== '') {
      // eslint-disable-next-line no-restricted-globals
      handleApproval({ record: d, name: event.target.name });
    }
    else {
      d.CanInstallation[event.target.name] = display;
      d.LastModifiedBy = user.email;
      d.LastModifiedDate = new Date().toJSON();
      d.CanInstallation.lastModifiedBy = user.email;
      d.CanInstallation.lastModifiedDate = new Date().toJSON();
      setData({ ...d });
    }
  };
  const isQcDisabled = !user.qc && !user.admin && !user.qa;
  return (
    <>
      <Breadcrumbs
        path={[
          { name: 'Fixtures', active: true, link: '/' },
          { name: `Can Installation - ${data.FixtureID}`, active: false }
        ]}
      />
      <div style={{
        display: 'flex', width: '100%', justifyContent: 'space-between', position: 'sticky', zIndex: '100', top: '0', backgroundColor: 'white'
      }}
      >
        <div>
          <Button><Link to={`/demo/${data.id}`} style={{ textDecoration: 'none', color: 'inherit' }}>Demo Checklist</Link></Button>
          &nbsp;
          <Button><Link to={`/fixture/${data.id}`} style={{ textDecoration: 'none', color: 'inherit' }}>Fixture Installation</Link></Button>
          &nbsp;
          <Button><Link to={`/pdf/${data.id}`} style={{ textDecoration: 'none', color: 'inherit' }}>PDF Output</Link></Button>
        </div>
        <div>
          {data._PreviousFixture.id !== '' ? <Button outline color="info"><Link style={{ textDecoration: 'none', color: 'inherit' }} to={`/can/${data._PreviousFixture.id}`}>&larr; {data._PreviousFixture.fixtureID}</Link></Button> : null}
          &nbsp;&nbsp;&nbsp;
          {data._NextFixture.id !== '' ? <Button outline color="info"><Link style={{ textDecoration: 'none', color: 'inherit' }} to={`/can/${data._NextFixture.id}`}>{data._NextFixture.fixtureID} &rarr;</Link></Button> : null}
        </div>
        <div>
          <Button className="bg-success" onClick={submit}>Save</Button>
          &nbsp;
          <Button className="bg-danger"><Link to="/" style={{ textDecoration: 'none', color: 'inherit' }}>Cancel</Link></Button>
        </div>
      </div>
      {
        submitLoading ? (
          <div style={{
            display: 'block', position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, backgroundColor: '#efefef', opacity: 0.5, zIndex: 100
          }}
          />
        ) : <></>
      }
      <Container fluid>
        {
          fixtureLoading ? (
            <div style={{
              display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'
            }}
            >
              <Loader
                type="TailSpin"
                color="#3b78e7"
                height={150}
                width={150}
              />
              Loading Fixture Data...
            </div>
          )
            : (
              <>
                <Confirm
                  proceedLabel={approvalState.proceedLabel}
                  cancelLabel={approvalState.cancelLabel}
                  title={approvalState.title}
                  modalBody={approvalState.modalBody}
                  show={approvalState.show}
                  proceed={approvalState.proceed}
                  enableEscape={approvalState.enableEscape}
                  record={approvalState.record}
                />
                <Form>
                  <Row>
                    <Col md={{ size: 4 }}>
                      <FormGroup>
                        <Label for="FixtureID">Fixture ID:</Label>
                        <Input type="text" name="FixtureID" value={data.FixtureID || ''} readOnly />
                      </FormGroup>
                    </Col>
                    <Col md={{ size: 4 }}>
                      <FormGroup>
                        <Label for="System">System:</Label>
                        <Input type="text" name="System" value={data.System || ''} readOnly />
                      </FormGroup>
                    </Col>
                    <Col md={{ size: 4 }}>
                      <FormGroup>
                        <Label for="BaseCan">Base Can:</Label>
                        <Input type="text" name="BaseCan" value={data.BaseCan || ''} readOnly />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={{ size: 12 }}>
                      <FormGroup check className="mb-3">
                        <Label check className="qc-color">
                          <Input onChange={handleUserConfirm} type="checkbox" name="BaseCompacted" disabled={isQcDisabled} checked={(data.CanInstallation.BaseCompacted || '') !== ''} />
                          Base Compacted to 100% Sign Off
                        </Label>
                        {displayConfirmedBy(data.CanInstallation.BaseCompacted)}
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={{ size: 12 }}>
                      <FormGroup check className="mb-3">
                        <Label check>
                          <Input onChange={handleChange} type="checkbox" name="HolesDrilledAndCleaned" checked={data.CanInstallation.HolesDrilledAndCleaned} /> Holes Drilled and Cleaned
                        </Label>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={{ size: 12 }}>
                      <FormGroup check className="mb-3">
                        <Label check>
                          <Input onChange={handleChange} type="checkbox" name="DowelsEpoxied" checked={data.CanInstallation.DowelsEpoxied} /> Dowels Epoxied
                        </Label>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={{ size: 12 }}>
                      <FormGroup check className="mb-3">
                        <Label check>
                          <Input onChange={handleChange} type="checkbox" name="GroundRodInstalled" checked={data.CanInstallation.GroundRodInstalled} /> Ground Rod Installed
                        </Label>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={{ size: 6 }}>
                      <FormGroup>
                        <FormText>Not to exceed 10k &Omega;</FormText>
                        <Label for="GroundResistance">Ground Resistance</Label>
                        <Input onChange={handleChange} type="number" name="GroundResistance" value={data.CanInstallation.GroundResistance} />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={{ size: 12 }}>
                      <FormGroup check className="mb-3">
                        <Label check>
                          <Input onChange={handleChange} type="checkbox" name="CounterpoisePresent" checked={data.CanInstallation.CounterpoisePresent} /> Counterpoise Present
                        </Label>
                      </FormGroup>
                      <FormGroup className="mb-3" hidden={!data.CanInstallation.CounterpoisePresent}>
                        <Label for="CounterpoiseSize">Counterpoise Size</Label>
                        <RadioGroup
                          name="CounterpoiseSize"
                          id="CounterpoiseSize"
                          onChange={(e, f) => {
                            handleChange(f);
                          }}
                          selectedValue={data.CanInstallation.CounterpoiseSize || ''}
                        >
                          <Label><Radio value="Yes" name="CounterpoiseSize" style={{ transform: 'scale(1.5)', marginRight: '5px' }} />1/0</Label>
                          <br />
                          <Label><Radio value="No" name="CounterpoiseSize" style={{ transform: 'scale(1.5)', marginRight: '5px' }} />6</Label>
                        </RadioGroup>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={{ size: 12 }}>
                      <FormGroup check className="mb-3">
                        <Label check>
                          <Input onChange={handleChange} type="checkbox" name="ConduitPreppedForConnection" checked={data.CanInstallation.ConduitPreppedForConnection} /> Conduit Prepped for Connection
                        </Label>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={{ size: 12 }}>
                      <FormGroup check className="mb-3">
                        <Label check>
                          <Input onChange={handleChange} type="checkbox" name="InstallRebarCageWithGround" checked={data.CanInstallation.InstallRebarCageWithGround} /> Install Rebar Cage w/ Ground
                        </Label>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={{ size: 12 }}>
                      <FormGroup check className="mb-3">
                        <Label check>
                          <Input onChange={handleChange} type="checkbox" name="CadweldGround" checked={data.CanInstallation.CadweldGround} /> Cadweld Ground Wire to Ground Rod
                        </Label>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={{ size: 12 }}>
                      <FormGroup check className="mb-3">
                        <Label check>
                          <Input onChange={handleChange} type="checkbox" name="SetBasecan" checked={data.CanInstallation.SetBasecan} /> Set Basecan and Connect Conduit
                        </Label>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={{ size: 12 }}>
                      <FormGroup check className="mb-3">
                        <Label check>
                          <Input onChange={handleChange} type="checkbox" name="ConfirmBasecan" checked={data.CanInstallation.ConfirmBasecan} /> Confirm Basecan Center w/ Offsets
                        </Label>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={{ size: 12 }}>
                      <FormGroup check className="mb-3">
                        <Label check>
                          <Input onChange={handleChange} type="checkbox" name="LevelBasecan" checked={data.CanInstallation.LevelBasecan} /> Level and Secure Basecan on Alignment Jig
                        </Label>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={{ size: 12 }}>
                      <FormGroup>
                        <Photos name="DemoPhoto" label="Pre Pour Photo Photos" multiple storageEndpoint={`${match.params.id}/PrePourPhoto`} zipEndpoint="zip" bucket={config.storageBucket} uploadText="Attach Photos" zipText="Download All" folder={`${match.params.id}/PrePourPhoto`} />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={{ size: 12 }}>
                      <FormGroup check className="mb-3">
                        <Label check className="qc-color">
                          <Input onChange={handleUserConfirm} type="checkbox" name="PrePourApproval" checked={(data.CanInstallation.PrePourApproval || '') !== ''} disabled={isQcDisabled} /> Pre-Pour Approved
                        </Label>
                        {displayConfirmedBy(data.CanInstallation.PrePourApproval)}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Label for="Notes">Notes</Label>
                      <Input type="textarea" id="Notes" name="Notes" onChange={handleChange} value={data.Notes} rows={6} />
                    </Col>
                  </Row>
                </Form>
              </>
            )
        }
      </Container>
    </>
  );
};

export default CanInstallation;
