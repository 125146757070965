import { atom } from 'recoil';

// Data

export const CurrentFixtureID = atom({
  key: 'fixtureid',
  default: -1
});

export const CurrentSystem = atom({
  key: 'system',
  default: -1
});

export const CurrentBaseCan = atom({
  key: 'basecan',
  default: -1
});
