/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React, {
  useState,
  useContext,
  useEffect,
  useRef
} from 'react';
import { Link } from 'react-router-dom';
import {
  Col,
  Container,
  Row,
  Form,
  FormGroup,
  Label,
  Input,
  Button
} from 'reactstrap';
import { toast } from 'react-toastify';

import Loader from 'react-loader-spinner';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';

import { UserContext } from '../providers/UserProvider';
import { useFirebase, useFirebaseMutation } from '../_helpers/fetch';
import Breadcrumbs from '../_GlobalComponents/breadcrumb';

const Notes = (props) => {
  const { match } = props;
  const submitToastId = useRef(null);
  const [data, setData] = useState({
    FixtureID: '',
    System: '',
    FixtureType: '',
    Notes: ''
  });

  const isComponentMounted = useRef(true);
  const {
    data: fixtureData,
    loading: fixtureLoading,
    error: fixtureError,
    firebaseCall: fixtureCall
  } = useFirebase('getFixtureData', { id: match.params.id }, isComponentMounted, [], 'data.id');
  const {
    data: submitData,
    loading: submitLoading,
    error: submitError,
    firebaseCall: submitCall
  } = useFirebaseMutation('submitFixtureData', { data });

  useEffect(() => {
    fixtureCall();
  }, []);

  useEffect(() => {
    if (fixtureData.length > 0) {
      setData(fixtureData[0]);
    }
  }, [fixtureData]);

  useEffect(() => {
    if (submitLoading) {
      submitToastId.current = toast('Submitting Record', {
        position: 'top-center',
        autoClose: false
      });
    }
    else if (!submitLoading && submitToastId.current !== null) {
      toast.dismiss(submitToastId.current);
    }
  }, [submitLoading]);

  useEffect(() => {
    if (typeof fixtureError !== 'undefined') {
      toast.error(`'Error Loading Fixture Data: ${fixtureError}`, { autoClose: 8000 });
    }
  }, [fixtureError]);

  useEffect(() => {
    if (typeof submitData !== 'undefined') {
      toast.success('Record saved successfully', { autoClose: 2000 });
    }
    if (typeof submitError !== 'undefined') {
      toast.error(`Record Failed to Save: ${submitError}.`, { autoClose: 8000 });
    }
  }, [submitData, submitLoading, submitError]);

  const user = useContext(UserContext);

  const handleChange = (event, name) => {
    try {
      const d = { ...data };

      if (typeof event.target !== 'undefined' && event.target.type.toLowerCase() === 'checkbox') {
        const id = name || event.target.name;
        d.CanInstallation[id] = event.target.checked;
      }
      else {
        const id = name || event.target.name;
        const value = typeof name !== 'undefined' ? event : event.target.value;
        if (id === 'Notes') {
          d[id] = value;
        }
        else {
          d.CanInstallation[id] = value;
        }
      }
      d.CanInstallation.lastModifiedBy = user.email;
      d.CanInstallation.lastModifiedDate = new Date().toJSON();
      setData({ ...d });
    }
    catch (err) {
      toast.error(err.message, { autoClose: 8000 });
    }
  };
  const submit = () => {
    submitCall();
  };
  return (
    <>
      <Breadcrumbs
        path={[
          { name: 'Fixtures', active: true, link: '/' },
          { name: `Notes - ${data.FixtureID}`, active: false }
        ]}
      />
      <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end' }}>
        <Button className="bg-success" onClick={submit}>Save</Button>
        &nbsp;
        <Button className="bg-danger"><Link to="/" style={{ textDecoration: 'none', color: 'inherit' }}>Cancel</Link></Button>
      </div>
      {
        submitLoading ? (
          <div style={{
            display: 'block', position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, backgroundColor: '#efefef', opacity: 0.5, zIndex: 100
          }}
          />
        ) : <></>
      }
      <Container fluid>
        {
          fixtureLoading ? (
            <div style={{
              display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'
            }}
            >
              <Loader
                type="TailSpin"
                color="#3b78e7"
                height={150}
                width={150}
              />
              Loading Fixture Data...
            </div>
          )
            : (
              <Form>
                <Row>
                  <Col md={{ size: 4 }}>
                    <FormGroup>
                      <Label for="FixtureID">Fixture ID:</Label>
                      <Input type="text" name="FixtureID" value={data.FixtureID} readOnly />
                    </FormGroup>
                  </Col>
                  <Col md={{ size: 4 }}>
                    <FormGroup>
                      <Label for="System">System:</Label>
                      <Input type="text" name="System" value={data.System} readOnly />
                    </FormGroup>
                  </Col>
                  <Col md={{ size: 4 }}>
                    <FormGroup>
                      <Label for="FixtureType">Fixture Type:</Label>
                      <Input type="text" name="FixtureType" value={data.FixtureType} readOnly />
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <Label for="Notes">Notes</Label>
                    <Input type="textarea" id="Notes" name="Notes" onChange={handleChange} value={data.Notes} rows={6} />
                  </Col>
                </Row>
              </Form>
            )
        }
      </Container>
    </>
  );
};

export default Notes;
