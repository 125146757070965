/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import PropTypes from 'prop-types';
import React from 'react';
// import { Link } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { Button, Table } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  //   faTrash,
  faSort,
  faSortDown,
  faSortUp
} from '@fortawesome/free-solid-svg-icons';
import { CurrentSystem, CurrentFixtureID, CurrentBaseCan } from '../_Recoil/atoms';

const FixtureTable = (props) => {
  const {
    fixtures,
    queryOptions,
    headerSelect,
    fetchMore
  } = props;

  const [currentfixtureid] = useRecoilState(CurrentFixtureID);
  const [currentsystem] = useRecoilState(CurrentSystem);
  const [currentbasecan] = useRecoilState(CurrentBaseCan);
  const moreRecords = (() => {
    if (fixtures.length > 0) {
      return fixtures.length % queryOptions.limit === 0;
    }
    return false;
  })();

  const sortIcon = (currentsort) => {
    if (typeof queryOptions.sort === 'undefined') return <></>;
    if (currentsort === queryOptions.sort && typeof queryOptions.direction !== 'undefined') {
      switch (queryOptions.direction.toLowerCase()) {
        case 'asc':
          return <FontAwesomeIcon icon={faSortUp} className="text-dark" />;
        case 'desc':
          return <FontAwesomeIcon icon={faSortDown} className="text-dark" />;
        default:
          return <FontAwesomeIcon icon={faSort} className="text-dark" />;
      }
    }
    else return <FontAwesomeIcon icon={faSort} className="text-dark" />;
  };

  return (
    <Table hover>
      <thead>
        <tr>
          <th style={{ cursor: 'pointer' }} onClick={() => { if (currentfixtureid === -1) headerSelect('FixtureID'); }}>
            Fixture ID {(currentfixtureid === -1) ? sortIcon('FixtureID') : <></>}
          </th>
          {/* <th style={{ cursor: 'pointer' }} onClick={() => { headerSelect('FixtureType'); }}>
            Fixture Type {sortIcon('FixtureType')}
          </th> */}
          <th style={{ cursor: 'pointer' }} onClick={() => { if (currentbasecan === -1) headerSelect('BaseCan'); }}>
            Base Can {(currentbasecan === -1) ? sortIcon('BaseCan') : <></>}
          </th>
          <th style={{ cursor: 'pointer' }} onClick={() => { if (currentsystem === -1) headerSelect('System'); }}>
            System {(currentsystem === -1) ? sortIcon('System') : <></>}
          </th>
          <th aria-label="Delete Record" />
        </tr>
      </thead>
      <tbody>
        {(fixtures.length !== 0) ? fixtures.map((res) => (
          <tr id={res.data.id} key={res.data.id} style={{ cursor: 'pointer' }}>
            <td>{res.data.FixtureID}</td>
            {/* <td>{res.data.FixtureType}</td> */}
            <td>{res.data.BaseCan}</td>
            <td>{res.data.System}</td>
            <td style={{ textAlign: 'right' }}>
              <Button className="mt-1" onClick={() => { props.onSelect('demo', res); }}>DEMO</Button><span>&nbsp;</span>
              {res.data.BaseCan !== 'EXISTING' ? (<><Button className="mt-1" onClick={() => { props.onSelect('can', res); }}>CAN</Button><span>&nbsp;</span></>) : <></>}
              <Button className="mt-1" onClick={() => { props.onSelect('fixture', res); }}>FIXTURE</Button><span>&nbsp;</span>
              <Button className="mt-1" onClick={() => { props.onSelect('pdf', res); }}>PDF</Button>
              {/* <Button className="mt-1" onClick={() => { props.onSelect('notes', res); }}>NOTES</Button> */}
            </td>
          </tr>
        )) : null}
      </tbody>
      <tfoot>
        <tr>
          {moreRecords ? <th colSpan="4" style={{ textAlign: 'center', cursor: 'pointer' }} onClick={() => fetchMore()}>Load More...</th> : <></>}
        </tr>
        <tr>
          <th colSpan="4" style={{ textAlign: 'center' }}>
            {(fixtures.length !== 0) ? `Showing ${fixtures.length} Records...` : <></>}
          </th>
        </tr>
      </tfoot>
    </Table>
  );
};

FixtureTable.propTypes = {
  //   inspections: PropTypes.arrayOf(PropTypes.object),
  queryOptions: PropTypes.shape({
    limit: PropTypes.number,
    offset: PropTypes.number,
    sort: PropTypes.string,
    direction: PropTypes.string
  }),
  headerSelect: PropTypes.func,
  //   onSelect: PropTypes.func,
  //   onDelete: PropTypes.func,
  //   fetchMore: PropTypes.func,
  //   open: PropTypes.bool.isRequired
};
FixtureTable.defaultProps = {
  //   inspections: [],
  queryOptions: {
    limit: 100,
    offset: 0,
    sort: null,
    direction: null
  },
  headerSelect: () => { },
  //   onSelect: () => { },
  //   onDelete: () => { },
  //   fetchMore: () => { },
};

export default FixtureTable;
